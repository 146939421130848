<template>
  <v-card flat>
    <v-card-text>
      <v-row class="pa-5">
        <v-col cols="12">
          <h3>Blog Setting</h3>
        </v-col>
        <v-col cols="12">
          <v-btn @click="blog_categoryLayoutDialog = true" class="btn btn-primary" outlined text>Blog Category Layout</v-btn>
        </v-col>
        <v-dialog scrollable v-model="blog_categoryLayoutDialog" max-width="900px">
          <websitelayout :type="'blog_category'" @closeDialog="blog_categoryLayoutDialog = false" />
        </v-dialog>
        <v-col cols="4">
          <v-select
            dense
            outlined
            v-model="setting.blog_sort_by"
            :items="['ASC', 'DESC']"
            label="Sort By"
            item-value="value"
            item-text="title"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Pagination Limit"
            type="number"
            min="0"
            dense
            outlined
            v-model="setting.blog_per_page"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-switch label="Social Share"></v-switch>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn @click="updateSetting" :loading="isBusy" color="primary">save</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Websitelayout from "../LayoutDialog";
export default {
      name:'blog-setting',
      props:['setting', 'isBusy'],
      components:{
            Websitelayout
      },
      data(){
        return{
             blog_categoryLayoutDialog: false,
             sort_by:[{
               "value":"w", title:"Ascending",
               "value":"z", title:"Descending"
             }],
        }
      },methods:{
          updateSetting(){
                  this.$emit('update_setting', this.setting);
            }
      }
};
</script>
