<template>
      <v-row class="pa-5">
        <v-col cols="12">
          <h3>Social Media</h3>
        </v-col>
        <v-col cols="6" md="6">
          <v-text-field
              v-model="social.facebook"
              hint="Full Url eg: https://www.facebook.com/yelkoNepal"
              persistent-hint
              label="Facebook"
              outlined
              densed
              prepend-inner-icon="socicon-facebook"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.facebook.$error">Not a valid url</p>
          <v-text-field
              v-model="social.youtube"
              hint="Full Url eg: https://www.youtube.com/yelkoNepal"
              persistent-hint
              label="Youtube"
              outlined
              densed
              prepend-inner-icon="socicon-youtube"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.youtube.$error">Not a valid url</p>

          <v-text-field
              v-model="social.instagram"
              hint="Full Url eg: https://www.instagram.com/yelkoNepal"
              persistent-hint
              label="Instagram"
              outlined
              densed
              prepend-inner-icon="socicon-instagram"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.instagram.$error">Not a valid url</p>
          <v-text-field
              v-model="social.viber"
              hint="Viber Number eg: 984XXXXXXX"
              persistent-hint
              label="Viber"
              prepend-inner-icon="socicon-viber"
              outlined
              densed
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.viber.$error">Not a valid number</p>
          <v-text-field
              hint="WhatsApp Number eg: 984XXXXXXX"
              persistent-hint
              v-model="social.whatsapp"
              label="Whatsapp"
              outlined
              densed
              prepend-inner-icon="socicon-whatsapp"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.whatsapp.$error">Not a valid number</p>
        </v-col>
        <v-col cols="6" md="6">
          <v-text-field
              v-model="social.twitter"
              hint="Full Url eg: https://www.twiter.com/yelkoNepal"
              persistent-hint
              label="Twitter"
              outlined
              densed
              prepend-inner-icon="socicon-twitter"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.twitter.$error">Not a valid url</p>

          <v-text-field
              v-model="social.pinterest"
              hint="Full Url eg: https://www.pininterest.com/yelkoNepal"
              persistent-hint
              label="Pinterest"
              outlined
              densed
              prepend-inner-icon="socicon-pinterest"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.pinterest.$error">Not a valid url</p>

          <v-text-field
              v-model="social.linkedin"
              hint="Full Url eg: https://www.linkedin.com/yelkoNepal"
              persistent-hint
              label="Linkedin"
              outlined
              densed
              prepend-inner-icon="socicon-linkedin"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.linkedin.$error">Not a valid url</p>
          <v-text-field
              v-model="social.vimeo"
              label="Vimeo"
              prepend-inner-icon="socicon-vimeo"
              outlined
              densed
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.vimeo.$error">Not a valid url</p>
          <v-text-field
              v-model="social.tiktok"
              label="TikTok"
              outlined
              densed
              prepend-inner-icon="socicon-tiktok"
          ></v-text-field>
          <p class="text-danger font-weight-bold" v-if="$v.social.tiktok.$error">Not a valid url</p>
        </v-col>

        <v-col cols="12" class="text-right">
          <v-btn @click="saveSocialMedia" :loading="isBusy" color="primary">save</v-btn>
        </v-col>
      </v-row>
</template>
<script>
import {maxLength, minLength, numeric, url} from "vuelidate/lib/validators";
import WebsiteSocial from "@/services/Websites/WebsiteSocialService";

const websiteSocialService = new WebsiteSocial();
export default {
  name: "social-media",
  props: ["domain_name", "isBusy"],
  data: () => ({
    isBusy: true,
    social: {
      facebook: null,
      twitter: null,
      instagram: null,
      youtube: null,
      linkedin: null,
      vimeo: null,
      tiktok: null,
      viber: null,
      whatsapp: null,
      pinterest: null
    }
  }),
  validations: {
    social: {
      facebook: {url},
      twitter: {url},
      instagram: {url},
      youtube: {url},
      linkedin: {url},
      vimeo: {url},
      tiktok: {url},
      viber: {numeric, minLength: minLength(10), maxLength: maxLength(13)},
      whatsapp: {numeric, minLength: minLength(10), maxLength: maxLength(13)},
      pinterest: {url}
    }
  },
  mounted() {
    //     alert(this.domain_name);
    this.getSocialSettings();
  },
  methods: {
    getSocialSettings() {
      websiteSocialService
          .paginate(this.domain_name)
          .then(res => {
            if (res.data.data[0]) {
              this.social = res.data.data[0];
            }
          })
          .catch(error => {
            // console.log(error);
          })
          .finally(() => {
            this.isBusy = false;
          });
    },
    saveSocialMedia() {
      this.isBusy = true;
      this.$v.$touch();
      if (this.$v.social.$error) {
        setTimeout(() => {
          this.$v.social.$reset();
          this.isBusy = false;
        }, 3000);
      } else {
        this.social.id
            ? this.updateSocialSetting()
            : this.createSocialSetting();
      }
    },
    updateSocialSetting() {
      websiteSocialService
          .update(this.domain_name, this.social.id, this.social)
          .then(res => {
            this.$snotify.success("Social Setting  Updated");
            this.getSocialSettings();
          })
          .catch(error => {
            // console.log(error);
          })
          .finally(() => {
            this.isBusy = false;
          });
    },
    createSocialSetting() {
      websiteSocialService
          .create(this.domain_name, this.social)
          .then(res => {
            this.$snotify.success("Social Setting  Updated");
            this.getSocialSettings();
          })
          .catch(error => {
            // console.log(error);
          })
          .finally(() => {
            this.isBusy = false;
          });
    }
  }
};
</script>