<template>
            <v-app>
              <v-card-text>
                <v-row class="pa-5">
                  <v-col cols="12">
                    <h3>API Settings</h3>
                  </v-col>
                  <v-col cols="12">
                    <!-- <v-text-field
                                                              v-model="pageSetting.mailchimp_api_key"
                                                              label="Mailchimp API Key"
                                                              dense
                                                              outlined
                                                              required
                    ></v-text-field>-->

                    <v-text-field
                      v-model="apikeys.google_map_api_key"
                      label="Google Map API Key"
                      dense
                      outlined
                      required
                      persistent-hint
                      hint="We strongly encourage you to load the Google Maps using an APIs Console key"
                    ></v-text-field>
                    <p>
                      You can get it from
                      <a
                        href="https://developers.google.com/maps/documentation/javascript/tutorial#api_key"
                      >here</a>
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="apikeys.google_map_embed_url"
                      label="Google Map Embeded URL"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h5>Google Site Verification</h5>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="apikeys.google_site_verification"
                      label="Google  Site Verification Key"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <h5>Google Recaptcha</h5>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="apikeys.google_recaptcha_site_key"
                      label="Google Recaptcha Site Key"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="apikeys.google_recaptcha_secret_key"
                      label="Google Recaptcha Secret Key"
                      dense
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
<!--                  <v-col cols="12">-->
<!--                    <h5>Viber</h5>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12">-->
<!--                    <v-text-field-->
<!--                      v-model="apikeys.viber_api"-->
<!--                      label="Viber api key"-->
<!--                      dense-->
<!--                      outlined-->
<!--                      required-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
                  <v-col cols="12" class="text-right">
                    <v-btn @click="updateKeys" :loading="isBusy" color="primary">save</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-app>
</template>
<script>
export default {
      name:'api-setting',
      props:['apikeys', 'isBusy'],
      mounted(){
      },methods:{
            updateKeys(){
                  this.$emit('update_api_keys');
            }
      }
}
</script>
