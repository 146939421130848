<template>

  <v-row class="pa-5">
    <v-col cols="12">
      <h3>General Settings</h3>
    </v-col>
    <v-col cols="6">
      <v-text-field
          v-model="general_setting.site_title"
          label="Title"
          outlined
          densed
          required
      ></v-text-field>
      <v-text-field
          v-model="general_setting.site_tagline"
          label="Tag Line"
          outlined
          densed
          required
      ></v-text-field>
      <VuePhoneNumberInput v-model="general_setting.site_phone" @phone-number-blur="phoneChanged" clearable
                           :default-country-code="general_setting.phone_international ? general_setting.phone_international.countryCode : 'NP'"
                           @update="setPhoneNo"
                           class="mb-2"/>

      <!--                    <v-text-field-->
      <!--                            v-model="general_setting.site_phone"-->
      <!--                            :error-messages="phoneErrors"-->
      <!--                            label="Contact Phone"-->
      <!--                            outlined-->
      <!--                            densed-->
      <!--                            required-->
      <!--                    ></v-text-field>-->
      <v-select
          densed
          :items="pages"
          v-model="general_setting.home_page_id"
          item-text="title"
          item-value="id"
          outlined
          label="Set Homepage"
      ></v-select>
    </v-col>
    <v-col cols="6">
      <v-text-field
          v-model="general_setting.site_fax"
          label="Contact Fax"
          outlined
          densed
          required
      ></v-text-field>
      <v-text-field
          v-model="general_setting.site_email"
          :error-messages="emailErrors"
          required
          label="Contact Email"
          outlined
          densed
          required
      ></v-text-field>
      <v-text-field
          v-model="general_setting.business_address"
          label="Contact Address"
          densed
          required
          outlined
      ></v-text-field>

      <v-select
          densed
          :items="pages"
          v-model="general_setting.blog_page_id"
          item-text="title"
          item-value="id"
          outlined
          label="Set Blogpage"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="12" md="12">
      <h5>Company Registration Information</h5>
    </v-col>
    <v-col cols="12" sm="12" md="12">
      <v-switch label="Show Registration No." v-model="general_setting.show_reg_no" >

      </v-switch>
    </v-col>
    <v-col cols="6" sm="6" md="6">
      <v-text-field label="Company Text/Type" :disabled="!general_setting.show_reg_no" outlined v-model="general_setting.reg_type" >

      </v-text-field>
    </v-col>
    <v-col cols="6" sm="6" md="6">
      <v-text-field
              v-model="general_setting.reg_no"
              :disabled="!general_setting.show_reg_no"
              label="Tax/Pan/Registration Number"
              densed
              required
              outlined
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="12">
      <h5>Basic Colors</h5>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <label>Primary Color </label>
      <verte
          menuPosition="bottom"
          model="hex"
          v-model="general_setting.primary_color"
      >
      </verte>
    </v-col>
    <v-col cols="12" sm="12" md="6">
      <label>Secondary Color </label>
      <verte
          menuPosition="bottom"
          model="hex"
          v-model="general_setting.secondary_color"
      >
      </verte>
    </v-col>

    <v-col cols="12" sm="12" md="12">
      <h5>Theme Colors</h5>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <label>Link Color </label>
      <verte
          menuPosition="bottom"
          model="hex"
          v-model="general_setting.theme_link_color"
      >
      </verte>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <label>Link Hover Color </label>
      <verte
          menuPosition="bottom"
          model="hex"
          v-model="general_setting.theme_link_hover_color"
      >
      </verte>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <label>Button Primary Color </label>
      <verte
          menuPosition="bottom"
          model="hex"
          v-model="general_setting.theme_btn_primary_color"
      >
      </verte>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <label>Button Secondary Color </label>
      <verte
          menuPosition="bottom"
          model="hex"
          v-model="general_setting.theme_btn_secondary_color"
      >
      </verte>
    </v-col>
    <v-col cols="12" sm="12" md="12">
      <h5>Button Shape</h5>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <label>Button Shape </label>
      <v-switch

              label="Flat/Rounded"
              v-model="general_setting.button_rounded"
      ></v-switch>
    </v-col>

<!--    <v-col cols="12" sm="12" md="6">-->
<!--      <label>Link Hover Color </label>-->
<!--      <verte-->
<!--              menuPosition="bottom"-->
<!--              model="hex"-->
<!--              v-model="general_setting.theme_link_hover_color"-->
<!--      >-->
<!--      </verte>-->
<!--    </v-col>-->
    <v-col cols="12" md="12" sm="12">
      <v-switch
          @change="enableDisableShopPage()"
          label="Activate Ecommerce / Online Shop"
          v-model="general_setting.sell_product_online"
      ></v-switch>
    </v-col>
    <v-col cols="12" md="12" sm="12">
      <v-switch
          @change="enablePreviewMode()"
          label="Enable Preview Mode"
          v-model="general_setting.enable_preview"
      ></v-switch>
    </v-col>

    <v-col cols="12" class="text-right">
      <v-btn
          @click="updateSetting"
          :loading="isBusy"
          color="primary"
      >Save
      </v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
import {required, email, helpers, numeric} from "vuelidate/lib/validators";
import WebsitePage from "@/services/Websites/WebsitePageService";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";


const WebsiteSettingService = new WebsiteSetting();
const websitePageService = new WebsitePage();
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

const numbercheck = helpers.regex(/^[a-zA-Z]*$/);

export default {
  name: 'general-setting',
  props: ['setting', "isBusy"],
  validations() {
    return {
      general_setting: {
        site_email: {required, email,}
      }
    }
  },
  components: {
    VuePhoneNumberInput
  },
  data: () => ({
    pages: [],
    general_setting: {
      site_title: null,
      site_tagline: null,
      site_phone: null,
      home_page_id: null,
      site_fax: null,
      site_email: null,
      business_address: null,
      blog_page_id: null,
      primary_color: null,
      secondary_color: null,
      theme_link_color: null,
      theme_link_hover_color: null,
      theme_btn_primary_color: null,
      theme_btn_secondary_color: null,
    }
  }),
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.general_setting.site_email.$dirty) return errors
      !this.$v.general_setting.site_email.email && errors.push('Must be valid e-mail')
      !this.$v.general_setting.site_email.required && errors.push('E-mail is required')
      return errors
    },
    phoneErrors() {
      const errors = []
      if (!this.$v.general_setting.site_phone.$dirty) return errors
      !this.$v.general_setting.site_phone.numeric && errors.push('Must be valid Phone')
      !this.$v.general_setting.site_phone.required && errors.push('Phone is required')
      return errors
    },
    siteUrl() {
      return this.$route.params.domainname;
    },
  },

  watch: {
    setting(newValue, oldValue) {
      this.general_setting = newValue;
    }
  },
  mounted() {
    this.general_setting = this.setting;
    this.general_setting.phone_international = JSON.parse(this.general_setting.phone_international);
    this.getPages()
  },
  methods: {
    //set Phone Number With International
    setPhoneNo(phone) {
      this.general_setting.phone_international = phone
    },
    //set Phone Number With International
    phoneChanged(phone) {

    },
    getPages(type = "default", flag = null) {
      websitePageService.paginate(this.siteUrl, type, flag).then(res => {

        this.pages = res.data.pages;
      });
    },
    enableDisableShopPage() {
      WebsiteSettingService.enableDisablePage(this.siteUrl, "Shop").then(
          res => {
            if (res.data) {
              this.updateSetting();
            }
          }
      );
    },
    updateSetting() {
      this.$v.general_setting.$touch();
      if (this.$v.general_setting.$error) {
        setTimeout(() => {
          this.$v.general_setting.$reset();
        }, 3000);
      } else {
        this.$emit('update_setting', this.setting);

      }
    }, enablePreviewMode() {
      WebsiteSettingService.enablePreviewMode(this.siteUrl).then(
          res => {
            if (res.data) {
              this.$snotify.success("Preview mode is now enabled successfully")
              this.updateSetting();
            }
          }
      );
    }
  }

}
</script>
