<template>
              <v-app>
                <v-row class="pa-5">
                  <v-col cols="12">
                    <h3>Sub Header</h3>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-switch v-model="header.show_sub_header" label="Enable"></v-switch>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-switch v-model="header.sub_header_global_settings" label="Activate on all pages"></v-switch>
                  </v-col>

<!--                  <v-col cols="12" sm="12" md="3">-->
<!--                    <v-switch-->
<!--                      v-model="header.sub_header_background_text_inverse"-->
<!--                      label="Inverse Color"-->
<!--                    ></v-switch>-->
<!--                  </v-col>-->
                  <!-- <v-col cols="12" sm="12" md="12">
                    <v-file-input
                      :rules="rules"
                      accept="image/png, image/jpeg, image/bmp"
                      hint="Upload background image"
                      persistent-hint
                      prepend-inner-icon="mdi-camera"
                      label="Background Image"
                      v-model="header.sub_header_background"
                      @change="changeFavIcon"
                      outlined
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <div class="logoPreview">
                      <img
                        style="max-height: 200px"
                        v-if="header_sub_background"
                        :src="header_sub_background"
                      />
                    </div>
                  </v-col> -->


                </v-row>
                <v-col cols="12" class="text-right">
                  <v-btn bottom @click="saveHeader" :loading="isBusy" color="primary">save</v-btn>
                </v-col>
              </v-app>
</template>
<script>
export default {
      name:'sub-header-setting',
      props: ["webiste", "setting", "header", "isBusy"],
  methods: {
    saveHeader() {
      this.$emit("save_header");
    }
  }
}
</script>
