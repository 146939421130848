<template>
  <v-app>
      <v-row class="pa-5">
        <v-col cols="12">
          <h3>Header</h3>
        </v-col>
        <v-col>
          <v-btn @click="headerLayoutDialog = true" class="btn btn-primary" outlined text
            >Change Header Layout</v-btn
          >
        </v-col>
        <v-dialog scrollable v-model="headerLayoutDialog" max-width="900px">
          <websitelayout
            :type="'header'"
            @closeDialog="headerLayoutDialog = false"
          />
        </v-dialog>

        <v-col cols="12"> </v-col>

<!--        <v-col cols="4" sm="4" md="4">-->
<!--          <v-switch v-model="header.is_custom" label="Custom Layout"></v-switch>-->
<!--        </v-col>-->

        <v-col cols="4" sm="4" md="4">
          <v-switch
            v-model="header.full_width_header"
            label="Full Width Header"
          ></v-switch>
        </v-col>
        <v-col cols="12"> </v-col>
        <v-col cols="4" sm="4" md="4">
          <v-switch
            v-model="header.transparent_header"
            label="Transparent Header"
          ></v-switch>
        </v-col>
        <v-col cols="12"> </v-col>
        <v-col cols="6" sm="4" md="3" v-if="header.transparent_header">
          <v-select
            :items="['dark', 'light']"
            v-model="header.transparent_header_style"
            outlined
            dense
            label="Background Style"
          ></v-select>
        </v-col>

        <v-col cols="6" sm="4" md="3" v-if="header.transparent_header">
          <v-select
            :items="['global', 'home', 'none']"
            v-model="header.transparent_header_page_setting"
            outlined
            dense
            label="Display setting"
          ></v-select>
        </v-col>

<!--        <v-row cols="12" v-if="header.transparent_header_style == 'custom'">-->
<!--          <v-col cols="12">-->
<!--            <h5>Header Colors</h5>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="12" md="3">-->
<!--            <label>Header Background Color</label>-->
<!--            <verte-->
<!--              menuPosition="bottom"-->
<!--              model="hex"-->
<!--              v-model="header.header_background_color"-->
<!--            ></verte>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="12" md="3">-->
<!--            <label>Header Menu Text Color</label>-->
<!--            <verte-->
<!--              menuPosition="bottom"-->
<!--              model="hex"-->
<!--              v-model="header.header_menu_text_color"-->
<!--            >-->
<!--              <svg viewBox="0 0 24 24">-->
<!--                <path d="M0 20h24v4H0z" />-->
<!--                <path-->
<!--                  style="fill: #000"-->
<!--                  d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"-->
<!--                />-->
<!--              </svg>-->
<!--            </verte>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="12" md="3">-->
<!--            <label>Menu Hover Text Color</label>-->
<!--            <verte-->
<!--              menuPosition="bottom"-->
<!--              model="hex"-->
<!--              v-model="header.menu_hover_text_color"-->
<!--            >-->
<!--              <svg viewBox="0 0 24 24">-->
<!--                <path d="M0 20h24v4H0z" />-->
<!--                <path-->
<!--                  style="fill: #000"-->
<!--                  d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"-->
<!--                />-->
<!--              </svg>-->
<!--            </verte>-->
<!--          </v-col>-->
<!--        </v-row>-->



        <hr />
      </v-row>
      <v-col cols="12" class="text-right">
        <v-btn bottom @click="saveHeader" :loading="isBusy" color="primary"
          >save</v-btn
        >
      </v-col>
  </v-app>
</template>
<script>
import Websitelayout from "./../LayoutDialog";
import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";
const WebsiteHeaderService = new WebsiteHeader();
export default {
  name: "header-setting",
  props: ["webiste", "setting", "header", "isBusy"],
  components: {
    Websitelayout,
  },
  methods: {
    saveHeader() {
      this.$emit("save_header");
    },
  },
  data() {
    return {
      search: null,
      entries: [],
      headerLayoutDialog: false,
      footerLayoutDialog: false,
    };
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isBusy) return;

      this.isBusy = true;

      // Lazily load input items
      WebsiteHeaderService.getGoogleFonts(val)
        .then((res) => {
          this.entries = res.data.items;
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => (this.isBusy = false));
    },
  },

  computed: {
    siteUrl() {
      return this.$route.params.domainname;
    },

    items() {
      return this.entries.map((entry, index) => {
        const Description = entry.family;
        return Object.assign({}, entry, {
          Description,
        });
      });
    },
  },
};
</script>
