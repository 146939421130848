<template>
  <div>
    <h3>Website Settings</h3>
    <v-btn
        text
        :to="{ name: 'manage-websites', params: { domainname: siteUrl } }"
        outlined
        class="btn btn-lg btn-secondary-main"
        style="background: transparent"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <v-btn v-if="!pageSetting.enable_preview"
        @click="publishSetting"
        text
        outlined
        class="btn btn-lg btn-add-main btn-primary float-right ml-2"
        style="background: transparent"
    >
      <i class="fas fa-upload"></i>
      Publish Setting
    </v-btn>
<!--    <pre>{{website}}</pre>-->
    <v-btn v-if="pageSetting.enable_preview"
           @click="restoreSetting"
           text
           outlined
           class="btn btn-lg btn-primary float-right"
           style="background: transparent"
    >
      <i class="fas fa-undo"></i>
      Restore Setting
    </v-btn>
    <br/>
    <br/>
    <div class="row" v-if="loading">
      <div class="col-md-12 mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line"></v-skeleton-loader>
      </div>
    </div>
    <v-app v-else>
      <v-tabs :vertical="!$vuetify.breakpoint.xsOnly" class="theme_settings">
        <v-tab>
          <v-icon left>mdi-cogs</v-icon>
          General
        </v-tab>
        <v-tab>
          <v-icon left>mdi-ambulance</v-icon>
          Maintenance
        </v-tab>
        <v-tab>
          <v-icon left>mdi-file-image-outline</v-icon>
          Logo
        </v-tab>

        <!--          <v-tab>-->
        <!--            <v-icon left>vertical_align_top</v-icon>Top Bar-->
        <!--          </v-tab>-->
        <v-tab>
          <v-icon left>mdi-page-layout-header</v-icon>
          Header
        </v-tab>
        <v-tab>
          <v-icon left>flaticon2-browser-1</v-icon>
          Sticky Header
        </v-tab>
        <v-tab>
          <v-icon left>mdi-page-layout-header-footer</v-icon>
          Sub Header
        </v-tab>
        <v-tab>
          <v-icon left>flaticon2-fast-next</v-icon>
          Action Button
        </v-tab>
        <v-tab>
          <v-icon left>mdi-page-layout-footer</v-icon>
          Footer
        </v-tab>
        <v-tab>
          <v-icon left>mdi-link</v-icon>
          Social Media
        </v-tab>
        <v-tab>
          <v-icon left>mdi-lock</v-icon>
          Blog
        </v-tab>
        <v-tab>
          <v-icon left>shopping_cart</v-icon>
          Ecommerce
        </v-tab>
        <v-tab>
          <v-icon left>mdi-blogger</v-icon>
          SEO & Social Share
        </v-tab>
        <v-tab>
          <v-icon left>mdi-code-tags</v-icon>
          API
        </v-tab>
        <v-tab>
          <v-icon left>mdi-brush</v-icon>
          Custom Style
        </v-tab>

        <!-- General Setting -->
        <v-tab-item>
          <general-setting
              v-if="pageSetting.id"
              :setting="pageSetting"
              @update_setting="updateSetting"
              :isBusy="isBusy"
          ></general-setting>
        </v-tab-item>
        <!-- General Setting -->
        <!-- Maintanence Setting -->
        <v-tab-item>
          <maintenance
              v-if="pageSetting.id"
              :setting="pageSetting"
              @update_setting="updateSetting"
              :isBusy="isBusy"
          ></maintenance>
        </v-tab-item>
        <!-- Maintanence Setting -->

        <!-- Logo -->

        <v-tab-item>
          <logo-setting
              v-if="pageSetting.id"
              :setting="pageSetting"
              :isBusy="isBusy"
              @upload_media="uploadMedia"
              @remove_image="removeImage"
          ></logo-setting>
        </v-tab-item>
        <!-- Logo -->

        <!-- Top Bar -->
        <!--          <v-tab-item>-->
        <!--            <top-bar-setting-->
        <!--              :website="website"-->
        <!--              :setting="pageSetting"-->
        <!--              :header="header"-->
        <!--              :isBusy="isBusy"-->
        <!--              @save_header="saveHeader"-->
        <!--            ></top-bar-setting>-->
        <!--          </v-tab-item>-->
        <!-- Top Bar -->

        <!-- Header -->
        <v-tab-item>
          <header-setting
              :website="website"
              :setting="pageSetting"
              :header="header"
              :isBusy="isBusy"
              @save_header="saveHeader"
          ></header-setting>
        </v-tab-item>
        <!-- Header -->

        <!-- Sticky Header -->
        <v-tab-item>
          <sticky-header-setting
              :website="website"
              :setting="pageSetting"
              :header="header"
              :isBusy="isBusy"
              @save_header="saveHeader"
              @remove_header_image="removeHeaderImage"
          ></sticky-header-setting>
        </v-tab-item>
        <!--Sticky  Header -->

        <!-- Sub Header -->
        <v-tab-item>
          <sub-header-setting
              :website="website"
              :setting="pageSetting"
              :header="header"
              :isBusy="isBusy"
              @save_header="saveHeader"
          ></sub-header-setting>
        </v-tab-item>
        <!-- Sub Header -->

        <!-- Action Button -->
        <v-tab-item>
          <action-button-setting
              :website="website"
              :setting="pageSetting"
              :header="header"
              :footer="footer"
              :isBusy="isBusy"
              @save_header="saveHeader"
          ></action-button-setting>
        </v-tab-item>
        <!-- Action button -->

        <!-- Footer -->
        <v-tab-item>
          <footer-setting
              :website="website"
              :setting="pageSetting"
              :footer="footer"
              :isBusy="isBusy"
              @save_footer="saveFooter"
              @delete_footer_logo="deleteFooterLogo"
          ></footer-setting>
        </v-tab-item>
        <!-- Footer -->

        <!-- Social Media -->
        <v-tab-item>
          <social-media v-if="siteUrl" :domain_name="siteUrl"></social-media>
        </v-tab-item>
        <!-- Social Media -->
        <!-- Blog -->
        <v-tab-item>
          <blog-setting
              v-if="siteUrl"
              :setting="pageSetting"
              :isBusy="isBusy"
              @update_setting="updateSetting"></blog-setting>
        </v-tab-item>

        <!-- Blog -->

        <!-- Ecommerce -->
        <v-tab-item>
          <ecommerce-setting
              :website="website"
              :setting="pageSetting"
              :header="header"
              :isBusy="isBusy"
              @save_header="saveHeader"
              @update_setting="updateSetting"
              @toggle_shop_enable="enableDisableShopPage"
          ></ecommerce-setting>
        </v-tab-item>
        <!-- Ecommerce -->
        <!-- Website & SEO -->
        <v-tab-item>
          <seo-setting
              v-if="siteUrl"
              :domain_name="siteUrl"
              :setting="pageSetting"
              :isBusy="isBusy"
              @update_setting="updateSetting"
              @upload_social_media="uploadSocialImage"
          ></seo-setting>
        </v-tab-item>
        <!-- Website & SEO -->

        <!-- API -->
        <!-- <pre>{{apikeys}}</pre> -->
        <v-tab-item>
          <api-setting
              v-if="apikeys"
              :apikeys="apikeys"
              :isBusy="isBusy"
              @update_api_keys="updateKeys"
          ></api-setting>
        </v-tab-item>
        <!-- API  -->

        <!-- Custom CSS -->

        <v-tab-item>
          <custom-style-setting
              v-if="siteUrl"
              :domain_name="siteUrl"
              :setting="pageSetting"
              :header="header"
              :footer="footer"
              :isBusy="isBusy"
              @update_setting="updateSetting"
              @init_setting="initSettings"
          ></custom-style-setting>
        </v-tab-item>
        <!-- Custom CSS -->
      </v-tabs>
      <!-- <pre>{{pageSetting}}</pre> -->
    </v-app>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

import Website from "@/services/Websites/WebsiteService";
import WebsiteSetting from "@/services/Websites/WebsiteSettingService";
import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";
import WebsiteFooter from "@/services/Websites/WebsiteFooterService";
import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
import {url} from "vuelidate/lib/validators";

import Websitelayout from "./LayoutDialog";
import WebsiteTopbarSetting from "@/services/Websites/WebsiteTopbarSettingService";

// Setting components
import GeneralSetting from "./setting/General";
import LogoSetting from "./setting/Logo";
import SocialMedia from "./setting/SocialMedia";
import BlogSetting from "./setting/Blog";
import EcommerceSetting from "./setting/Ecommerce";
import SeoSetting from "./setting/Seo";
import ApiSetting from "./setting/Api";
import CustomStyleSetting from "./setting/CustomStyle";
import TopBarSetting from "./setting/TopBar";
import HeaderSetting from "./setting/Header";
import StickyHeaderSetting from "./setting/StickyHeader";
import SubHeaderSetting from "./setting/SubHeader";
import ActionButtonSetting from "./setting/ActionButton";
import FooterSetting from "./setting/Footer";
import Maintenance from "./setting/Maintenance";

const WebsiteService = new Website();
const WebsiteSettingService = new WebsiteSetting();
const WebsiteHeaderService = new WebsiteHeader();
const WebsiteFooterService = new WebsiteFooter();
const ProductRoot = new ProductCatalogRootService();
const WebsiteTopbarSettingService = new WebsiteTopbarSetting();
export default {
  name: "website-settings",
  components: {
    Websitelayout,
    GeneralSetting,
    Maintenance,
    LogoSetting,
    SocialMedia,
    BlogSetting,
    EcommerceSetting,
    SeoSetting,
    ApiSetting,
    CustomStyleSetting,
    TopBarSetting,
    HeaderSetting,
    StickyHeaderSetting,
    SubHeaderSetting,
    ActionButtonSetting,
    FooterSetting
  },
  validations: {
    social: {
      facebook: {
        url
      },
      twitter: {
        url
      },
      instagram: {
        url
      },
      youtube: {
        url
      },
      linkedin: {
        url
      },
      vimeo: {
        url
      },
      tiktok: {
        url
      },
      viber: {
        url
      },
      whatsapp: {
        url
      },
      pinterest: {
        url
      }
    }
  },
  data() {
    return {
      errorMsg: null,
      headerLayoutDialog: false,
      jsCode: "// your Js Code here",
      cssCode: "// your Css Code here",
      isBusy: false,
      loading: true,
      search: null,
      entries: [],
      valueNull: null,
      overlay: false,
      socialShareDiaolog: false,
      website: null,
      model: "",
      rules: [
        value =>
            !value ||
            value.size < 2000000 ||
            "Avatar size should be less than 2 MB!"
      ],
      selectedLayout: {
        id: null,
        title: "",
        path: "",
        thumbnail_thumb_image: "",
        thumbnail_image: "",
        type: "",
        is_active: 1
      },
      favRules: [],
      storeAddress: {
        website_id: 1,
        geolocation_id: null,
        address_line_1: "ar",
        city: "ar",
        state: "ar",
        is_active: 1
      },
      url: null,
      date: new Date().toISOString().substr(0, 7),
      catalogroots: [],
      selectedRootId: null,
      pageSetting: {
        id: null,
        site_logo: null,
        site_favicon: null,
        site_title: null,
        site_tagline: null,
        site_phone: null,
        site_email: null,
        site_fax: null,
        business_address: null,
        seo_title: null,
        seo_description: null,
        social_share_image: null,
        social_share_description: null,
        enable_powered_by: 1,
        mailchimp_api_key: null,
        google_map_api_key: null,
        google_analytics_api_key: null,
        google_map_embed_url: null,
        header_design_id: null,
        footer_design_id: null,
        cart_page_design_id: null,
        cart_page_title: null,
        cart_page_header: null,
        cart_page_subheader: null,
        cart_page_banner: null,
        shop_main_design_id: null,
        shop_page_title: null,
        shop_page_header: null,
        shop_page_subheader: null,
        shop_page_banner: null,
        product_per_page: null,
        thank_you_page_design_id: null,
        thank_you_page_title: null,
        thank_you_page_text: "",
        thank_you_page_header: null,
        thank_you_page_subheader: null,
        thank_you_page_banner: null,
        checkout_page_design_id: null,
        blog_main_design_id: null,
        blog_single_design_id: null,
        blog_page_title: null,
        blog_page_header: null,
        blog_page_subheader: null,
        blog_page_banner: null,
        blog_per_page: null,
        theme_link_color: null,
        theme_btn_primary_color: null,
        theme_btn_secondary_color: null,
        theme_link_hover_color: null,
        is_active: 0,
        deleted_at: null,
        updated_at: null
      },
      apikeys: {},
      header_sub_background: null,

      social: {
        id: null,
        website_id: null,
        facebook: "#",
        twitter: "#",
        instagram: "#",
        youtube: "#",
        linkedin: "#",
        vimeo: "#",
        tiktok: "#",
        viber: "#",
        whatsapp: "#",
        pinterest: "#",
        youtube_channel_link: "#",
        created_at: null,
        updated_at: null,
        deleted_at: null
      },
      topBar: {},
      header: {},
      footer: {},

      layouts: [],

      logoUrl: null,
      favIconUrl: null,
      scrollLogoUrl: null
    };
  },
  methods: {
    changePage(value) {
    },
    showDialog() {
      this.socialShareDiaolog = true;
    },
    closeDialog() {
      this.socialShareDiaolog = false;
    },
    getWebsite() {
      WebsiteService.get(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },

    delele(id) {
    },
    savePage() {
      this.$v.$touch();
      if (!this.$v.page.$error) {
        z;
        //call api
        //close Dialog
        //refesh Data
        // success Notification
      }
    },
    saveSeoSetting() {
      this.toggleBusy();
      WebsiteSettingService.update(this.siteUrl, this.page.id, this.page)
          .then(res => {
            this.isBusy = false;
            this.$snotify.success("Page Seo Update");
            this.initSettings();
          })
          .catch(err => {
          })
          .finally(() => (this.isBusy = false));
    },
    uploadSocialImage(data) {
      this.isBusy = true;
      WebsiteSettingService.update(this.siteUrl, this.pageSetting.id, data)
          .then(res => {
            (this.logoUrl = null), (this.favIconUrl = null), this.toggleBusy();
            this.$snotify.success("Settings  Updated");
            this.initSettings();
          })
          .catch(err => {
          })
          .finally(() => (this.isBusy = false));
    },
    enableDisableShopPage() {
      WebsiteSettingService.enableDisablePage(this.siteUrl, "Shop").then(
          res => {
            if (res.data) {
              this.updateSetting();
            }
          }
      );
    },

    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    getSettings() {
      WebsiteSettingService.paginate(this.siteUrl).then(res => {
        this.pageSetting = res.data;
        this.apikeys.google_recaptcha_secret_key =
            res.data.google_recaptcha_secret_key;
        this.apikeys.google_recaptcha_site_key =
            res.data.google_recaptcha_site_key;
        this.apikeys.google_map_api_key = res.data.google_map_api_key;
        this.apikeys.google_site_verification =
            res.data.google_site_verification;
        this.apikeys.google_map_embed_url = res.data.google_map_embed_url;
        this.apikeys.viber_api = res.data.viber_api;
        if (this.pageSetting.custom_css === null) {
          this.pageSetting.custom_css = "//your code here";
        }
        if (this.pageSetting.custom_js === null) {
          this.pageSetting.custom_js = "//your code here";
        }
        this.loading = false;
      });
    },
    getHeader() {
      WebsiteHeaderService.paginate(this.siteUrl).then(res => {
        if (res.data) {
          this.header = res.data;
          this.header.sub_header_background_path
              ? (this.header_sub_background = this.header.sub_header_background_path.real)
              : null;
          this.loading = false;
        }
      });
    },
    getFooter() {
      WebsiteFooterService.paginate(this.siteUrl).then(res => {
        if (res.data) {
          this.footer = res.data;
          this.footer.footer_logo
              ? (this.footer_logo = this.footer.footer_logo_image.real)
              : null;
          this.footer.footer_logo = null;
          this.loading = false;
        }
      });
    },
    initSettings() {
      this.getSettings();
      this.getHeader();
      this.getFooter();
    },
    updateSetting() {
      if (this.pageSetting.home_page_id != null) {
        this.pageSetting.is_system_ecommerce_page = 0;
      }
      WebsiteSettingService.update(
          this.siteUrl,
          this.pageSetting.id,
          this.pageSetting
      ).then(res => {
        this.$snotify.success("Settings  Updated");
        this.initSettings();
      });
    },
    updateKeys() {
      this.toggleBusy();
      WebsiteSettingService.update(
          this.siteUrl,
          this.pageSetting.id,
          this.apikeys
      ).then(res => {
        this.toggleBusy();
        this.$snotify.success("Settings  Updated");
        this.initSettings();
      });
    },

    saveHeader() {
      this.header.id ? this.updateHeader() : this.createHeader();
    },
    saveFooter() {
      this.footer.id ? this.updateFooter() : this.createFooter();
    },
    deleteFooterLogo() {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteFooterService.deleteFooterLogo(this.siteUrl, this.footer.id).then(response => {
              this.getFooter()
            })
          }
        }
      })
    },
    updateHeader() {
      this.toggleBusy();
      let fd = new FormData();
      for (var key in this.header) {
        if (
            key === "sub_header_background" &&
            (this.header["sub_header_background"] == null ||
                this.header["sub_header_background"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "button_link" &&
            (this.header["button_link"] == null ||
                this.header["button_link"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "button_text" &&
            (this.header["button_text"] == null ||
                this.header["button_text"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "sub_header_background_color" &&
            (this.header["sub_header_background_color"] == null ||
                this.header["sub_header_background_color"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "scroll_header_logo" &&
            (this.header["scroll_header_logo"] == null ||
                this.header["scroll_header_logo"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, this.header[key] == "1" ? "1" : this.header[key]);
        }
      }

      WebsiteHeaderService.update(this.siteUrl, this.header.id, fd).then(
          res => {
            this.toggleBusy();
            this.updateSetting();
            this.$snotify.success("PageHeader Settings Updated");
          }
      );
    },

    createHeader() {
      this.toggleBusy();
      let fd = new FormData();
      for (var key in this.header) {
        if (
            key === "sub_header_background" &&
            (this.header["sub_header_background"] == null ||
                this.header["sub_header_background"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "button_link" &&
            (this.header["button_link"] == null ||
                this.header["button_link"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "button_text" &&
            (this.header["button_text"] == null ||
                this.header["button_text"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "sub_header_background_color" &&
            (this.header["sub_header_background_color"] == null ||
                this.header["sub_header_background_color"] == undefined)
        ) {
          fd.append(key, "");
        } else if (
            key === "scroll_header_logo" &&
            (this.header["scroll_header_logo"] == null ||
                this.header["scroll_header_logo"] == undefined)
        ) {
          fd.append(key, "");
        } else {
          fd.append(key, this.header[key] == "1" ? "1" : this.header[key]);
        }
      }

      WebsiteHeaderService.create(this.siteUrl, this.header.id, fd).then(
          res => {
            this.updateSetting();
            this.initSettings();
            this.toggleBusy();
            this.$snotify.success("PageHeader Settings Created");
          }
      );
    },
    updateFooter() {
      // WebsiteFooterService.update(
      //   this.siteUrl,
      //   this.footer.id,
      //   this.footer
      // ).then(res => {
      //   this.$snotify.success("Footer Setting  Updated");
      //   this.initSettings();
      // });
      this.toggleBusy();
      let fd = new FormData();
      for (var key in this.footer) {
        if (
            key === "footer_logo" &&
            (this.footer["footer_logo"] == null ||
                this.footer["footer_logo"] == undefined)
        ) {
        } else {
          fd.append(key, this.footer[key] == "1" ? "1" : this.footer[key]);
        }
      }

      WebsiteFooterService.update(this.siteUrl, this.footer.id, fd).then(
          res => {
            this.initSettings();
            this.toggleBusy();
            this.$snotify.success("Footer Settings Updated");
          }
      );
    },
    createFooter() {
      // WebsiteFooterService.create(this.siteUrl, this.footer).then(res => {
      //   this.$snotify.success("Footer Setting  Updated")
      //   this.initSettings();
      // });
      this.toggleBusy();
      let fd = new FormData();
      for (var key in this.footer) {
        if (
            key === "footer_logo" &&
            (this.footer["footer_logo"] == null ||
                this.footer["footer_logo"] == undefined)
        ) {
        } else {
          fd.append(key, this.footer[key] == "1" ? "1" : this.footer[key]);
        }
      }

      WebsiteFooterService.create(this.siteUrl, fd).then(res => {
        this.initSettings();
        this.toggleBusy();
        this.$snotify.success("Footer Settings Created");
      });
    },

    getCatalogsRoots() {
      ProductRoot.paginate().then(res => {
        this.catalogroots = res.data;
      });
    },

    getGoogleFonts() {
      WebsiteHeaderService.getGoogleFonts().then(res => {
        // console.log(res);
      });
    },
    uploadMedia(fd) {
      this.toggleBusy();
      WebsiteSettingService.update(this.siteUrl, this.pageSetting.id, fd).then(
          res => {
            (this.logoUrl = null), (this.favIconUrl = null), this.toggleBusy();
            this.$snotify.success("Settings  Updated");
            this.initSettings();
          }
      );
      //call Api to upload
    },

    updateSubHeader() {
      let fd = new FormData();
      this.toggleBusy();
      for (var key in this.header) {
        if (
            key === "sub_header_background" &&
            (this.header["sub_header_background"] == null ||
                this.header["sub_header_background"] == undefined)
        ) {
        } else {
          fd.append(key, this.header[key]);
        }
      }

      WebsiteHeaderService.update(this.siteUrl, this.header.id, fd).then(
          res => {
            this.toggleBusy();
            this.$snotify.success("PageHeader Settings Updated");
          }
      );
    },
    removeImage(image) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteSettingService.removeImage(this.siteUrl, image)
                .then(response => {
                  this.getSettings();
                })
                .catch(error => {
                  // console.log(error);
                });
          }
        }
      });
    },
    changeScrollHeaderLogo(e) {
      this.header.scroll_header_logo = e;
      this.scrollLogoUrl = URL.createObjectURL(e);
    },

    changeFavIcon(e) {
      this.setting.site_favicon = e;
      this.favIconUrl = URL.createObjectURL(e);
    },

    removeHeaderImage(image) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteHeaderService.removeImage(this.siteUrl, image)
                .then(response => {
                  this.getHeader();
                })
                .catch(error => {
                  // console.log(error);
                });
          }
        }
      });
    }, publishSetting() {
      this.$confirm({
        message: `Are you sure you want to publish new setting?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteSettingService.publishSetting(this.siteUrl)
                .then(response => {
                  this.$snotify.success("Website setting has been queued to publish. It may takes 3-5 minutes to appear changes.")
                })
                .catch(error => {
                  this.$snotify.error("Something went wrong")
                });
          }
        }
      });
    }, restoreSetting() {
      this.$confirm({
        message: `Are you sure you want to restore old setting?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            WebsiteSettingService.restoreSetting(this.siteUrl)
                .then(response => {
                  this.$snotify.success("Website setting restoration has been queued to publish. It may takes 3-5 minutes to appear changes.")
                })
                .catch(error => {
                  this.$snotify.error("Something went wrong")
                });
          }
        }
      });
    },
  },
  computed: {
    siteUrl() {
      return this.$route.params.domainname;
    },

    items() {
      return this.entries.map((entry, index) => {
        const Description = entry.family;
        return Object.assign({}, entry, {
          Description
        });
      });
    }
  },

  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isBusy) return;

      this.isBusy = true;

      // Lazily load input items
      WebsiteHeaderService.getGoogleFonts(val)
          .then(res => {
            this.entries = res.data.items;
          })
          .catch(err => {
            // console.log(err);
          })
          .finally(() => (this.isBusy = false));
    }
  },

  mounted() {
    this.getWebsite();
    this.initSettings();
    this.getCatalogsRoots();
    //this.getGoogleFonts();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Manage Pages",
        route: "/websites"
      }
    ]);
  }
};
</script>

<style scoped>
.logoPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#logoPreview img {
  max-width: 100%;
  max-height: 250px !important;
}

#favIconPreview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#favIconPreview img {
  max-width: 100%;
  max-height: 250px;
}

.my-editor {
  height: 300px;
}

.seo-title {
  color: darkblue !important;
}

#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 250px;
}
</style>
