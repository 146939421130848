<template>
    <v-app class="pa-5">
      <v-row class="">
        <v-col cols="12">
          <h3>Sticky Header</h3>
        </v-col>

        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="header.is_fixed_header" label="Enable"></v-switch>
        </v-col>
      </v-row>

      <v-row v-if="header.is_fixed_header" class="">
        <v-col cols="12">
          <h5>Sticky Header Logo</h5>
        </v-col>
        <v-col cols="12">
          <v-file-input
            accept="image/png, image/jpeg, image/bmp"
            hint="Upload Logo"
            persistent-hint
            prepend-inner-icon="mdi-camera"
            prepend-icon=""
            label="Scroll Header Logo"
            v-model="header.scroll_header_logo"
            @change="changeLogo"
            outlined
          ></v-file-input>
        </v-col>
        <v-col cols="12">
          <div class="logoPreview" id="logoPreview">
            <img
              v-if="header.scroll_header_logo_image"
              style="max-height: 180px"
              :src="header.scroll_header_logo_image"
            /><br/><br/>
            <a
              href="#"
              v-if="header.scroll_header_logo_image"
              @click.prevent="removeHeaderImage('scroll-logo')"
              class="mt-5"
            >
              <i class="fas fa-trash text-danger"></i> Remove Logo
            </a>
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="header.scroll_header_logo_height"
            label="Logo Height"
            suffix="px"
            outlined
            densed type="number" min="0"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-col cols="12" class="text-right">
        <v-btn bottom @click="saveHeader" :loading="isBusy" color="primary">save</v-btn>
      </v-col>
    </v-app>
</template>
<script>
export default {
  name: "sticky-header-setting",
  props: ["webiste", "setting", "header", "isBusy"],
  data:()=>({
    scrollLogoUrl:null
  }),
  methods: {
    removeHeaderImage(type){
      this.$emit('remove_header_image', type)
    },
    saveHeader() {
        this.$emit('save_header');
    },  changeScrollHeaderLogo(e) {
      this.header.scroll_header_logo = e;
      this.scrollLogoUrl = URL.createObjectURL(e);
    },
  }
};
</script>
