<template>
    <v-row class="pa-5">
        <v-col cols="12" md="12" sm="12">
            <v-switch
                    @change="enableDisableShopPage()"
                    label="Activate Ecommerce / Online Shop"
                    v-model="setting.sell_product_online"
            ></v-switch>
        </v-col>



        <v-col class="text-right" cols="12" >
            <v-btn :loading="isBusy" @click="saveHeader" bottom color="primary">save</v-btn>
        </v-col>
    </v-row>
</template>
<script>

    export default {
        name: "ecommerce-setting",
        data() {
            return {
                product_details: [
                    {
                        icon: null,
                        title: null,
                        details: null,
                    }
                ],
            }
        },
        props: ["website", "setting", "header", 'isBusy'],

        mounted() {
            if (this.setting.product_detail_other_info){
                this.product_details = JSON.parse(this.setting.product_detail_other_info);
            }
        },
        methods: {

            saveHeader() {
                this.setting.product_detail_other_info = JSON.stringify(this.product_details);
                this.$emit('save_header')
                // this.$emit('update_setting')
            }, enableDisableShopPage() {
                this.$emit('toggle_shop_enable')
            },
            addProductDetails() {
                this.product_details.push({
                    icon: null,
                    title: null,
                    details: null,
                });
            },
            removeProductDetails(index) {
                this.product_details.splice(index,1);
            }
        }
    };
</script>
