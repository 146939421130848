<template>
  <v-app>
    <v-row class="pa-5">
        <v-col cols="12">
          <h3>Website SEO</h3>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Title for the website"
                v-model="setting.seo_title"
                required
                outlined
                densed
              ></v-text-field>
              <v-textarea
                v-model="setting.seo_description"
                label="Write short description about this website"
                outlined
                densed
              ></v-textarea>
              <v-combobox
                v-model="setting.seo_keyword"
                hint="Maximum of 5 keywords"
                label="Add Search Keywords"
                multiple
                outlined
                densed
                persistent-hint
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching " Press
                        <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <h5>
            Search Result Preview on
            <strong>Google</strong>
          </h5>
          <v-card outlined raised class="search-preview">
            <p>{{ domain_name }}</p>
            <h3>{{ setting.seo_title }}</h3>
            <p class="mt-2">{{ setting.seo_description }}</p>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-divider></v-divider>
          <h5>
            Social
            <strong>Share</strong>
          </h5>

          <v-card outlined raised>
            <v-img
              height="250"
              width="100%"
              v-bind:src="
                          setting.social_share_img
                            ? setting.social_share_img
                            : '/assets/media/logos/icon-no-image.svg'
                        "
            >
              <v-overlay :absolute="absolute">
                <v-btn text @click="showDialog">Change Image</v-btn>
              </v-overlay>
            </v-img>

            <v-card-title>{{ domain_name }}</v-card-title>
            <v-card-subtitle class="seo-title">{{ setting.seo_title }}</v-card-subtitle>
            <v-card-text>
              <p>{{ setting.seo_description }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn
            @click="updateSetting"
            :loading="isBusy"
            color="white--text v-btn theme--light elevation-2 v-size--large primary"
            class="btn-save-popup"
          >save</v-btn>
        </v-col>
      </v-row>
    <v-dialog scrollable v-model="socialShareDiaolog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload Social Share Image</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="form-block">
              <v-col cols="12" md="12">
                <v-file-input
                  :rules="rules"
                  v-model="page.social_share_image"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pick an Featured Image"
                  prepend-inner-icon="mdi-camera"
                  prepend-icon=""
                  persistent-hint
                  @change="imageChange"
                  hint="Also used for social Share"
                  label="Featured Image"
                  full-width
                  outlined
                  width="100%"
                ></v-file-input>
                <span class="text-danger">{{ errorMsg }}</span>
              </v-col>
              <v-col cols="12">
                <div id="preview">
                  <img v-if="url" :src="url" />
                </div>
              </v-col>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn
            color="blue darken-1"
            :loading="isBusy"
            class="btn btn-primary"
            @click.prevent="uploadSocialImage"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
export default {
  name: "seo-setting",
  props: ["domain_name", "setting", "isBusy"],
  data: () => ({
    socialShareDiaolog: false,
    absolute: true,
    page: {},
    url: null,
    errorMsg: null,
    rules: [
      value =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!"
    ]
  }),
  methods: {
    showDialog() {
      this.socialShareDiaolog = true;
    },
    closeDialog() {
      this.socialShareDiaolog = false;
    },
    imageChange(e) {
      this.url = URL.createObjectURL(e);
      this.setting.social_share_image = e;
    },
    updateSetting() {
      this.$emit("update_setting", this.setting);
    },
    uploadSocialImage() {
      if (!this.setting.social_share_image) {
        this.errorMsg = "Image Required";
      } else {
        let fd = new FormData();

        if (this.setting.social_share_image !== null) {
          fd.append(
            "social_share_image",
            this.setting.social_share_image,
            this.setting.social_share_image.name
          );
        }
        this.$emit("upload_social_media");
      }
    }
  }
};
</script>
