<template>
      <v-row class="pa-10">
        <v-col cols="12">
          <h3>Custom CSS</h3>
        </v-col>
        <v-col cols="12">
          <prism-editor
            v-model="setting.custom_css"
            autoStyleLineNumbers
            language="css"
            :line-numbers="lineNumbers"
            class="my-editor"
          />
        </v-col>
      </v-row>
</template>

<script>
export default {
  name: "custom-style-css",
}
</script>
