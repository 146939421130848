import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteStyleService {


    paginate(slug, index = null) {
        let url = API_URL + 'user/website/' + slug + '/style';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    index(slug, type) {
        let url = API_URL + 'user/website/' + slug + '/style/'+type;
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/style'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/style/' + id+'/update'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/style/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/style/' + id
        return apiService.get(url);
    }


    removeBackgroundImage(domainName,styleId) {
        let url = API_URL + `user/style/${domainName}/remove-background-image/${styleId}`;
        return apiService.get(url);
    }


}
