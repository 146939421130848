import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteFooterService {


    paginate(slug, index = null) {
        let url = API_URL + 'user/website/' + slug + '/footer';
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/footer'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/footer/' + id+'/update'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/footer/' + id;
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/footer/' + id;
        return apiService.get(url);
    }
    deleteFooterLogo(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/footer/' + id+'/delete-logo';
        return apiService.get(url);
    }


}
