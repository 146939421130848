<template>
      <v-row class="pa-10">
        <v-col cols="12">
          <h3>Logo</h3>
        </v-col>
        <v-col cols="12">
          <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              hint="Upload Logo"
              persistent-hint
              prepend-inner-icon="mdi-camera"
              prepend-icon=""
              label="Website Logo"
              v-model="setting.site_logo"
              @change="changeLogo"
              outlined
          ></v-file-input>
        </v-col>
        <v-col cols="12">
          <div class="logoPreview" id="logoPreview">
            <img
                v-if="setting.site_logo_image"
                style="max-height: 180px"
                :src="setting.site_logo_image"
            />
            <a
                href="#"
                v-if="setting.site_logo_image"
                @click.prevent="removeImage('logo')"
                class="ml-5 btn btn-sm"
            >
              <i class="fas fa-trash"></i>
            </a>
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="setting.logo_width"
              outlined
              densed
              suffix="px"
              label="Logo Width"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <h3>Favicon</h3>
        </v-col>
        <v-col cols="12">
          <v-file-input
              :rules="favRules"
              accept=".ico"
              hint="Pick an Favicon .ico Only supported"
              persistent-hint
              prepend-icon=""
              prepend-inner-icon="mdi-camera"
              label="Favicon Image"
              v-model="setting.site_favicon"
              @change="changeFavIcon"
              outlined
          ></v-file-input>
        </v-col>
        <v-col cols="6">
          <div id="favIconPreview">
            <img
                class="img-responsive"
                style="max-height: 50px"
                v-if="setting.site_favicon_image"
                :src="setting.site_favicon_image"
            />
            <a
                href="#"
                v-if="setting.site_favicon_image"
                @click.prevent="removeImage('fav-icon')"
                class="ml-5 btn btn-sm"
            >
              <i class="fas fa-trash"></i>
            </a>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <h5>Background for Login/Registration Page </h5>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              hint="Subject to display based on selected design only"
              persistent-hint
              prepend-inner-icon="mdi-camera"
              prepend-icon
              label="Auth cover image"
              v-model="setting.auth_cover_image"
              @change="changeAuthCoverImage"
              outlined
          ></v-file-input>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <div id="authCoverImage">
            <img
                class="img-responsive"
                style="max-height: 100px"
                v-if="setting.auth_cover_image_path && setting.auth_cover_image_path['thumb']"
                :src="setting.auth_cover_image_path['thumb']"
            />
            <a
                href="#"
                v-if="setting.auth_cover_image_path"
                @click.prevent="removeImage('auth-cover-image')"
                class="ml-5 btn btn-sm"
            >
              <i class="fas fa-trash"></i>
            </a>
          </div>
        </v-col>

        <v-col class="text-right">
          <v-btn @click="uploadMedia" color="primary" :isBusy="isBusy">
            Upload & Save
            <!-- <v-btn @click="uploadMedia" :loading="isBusy" color="primary">Upload & Save -->
          </v-btn>
        </v-col>
      </v-row>
</template>
<script>
export default {
  name: "logo-setting",
  props: ["setting", "isBusy"],
  data: () => ({
    logoUrl: null,
    favIconUrl: null,
    authCoverImageUrl: null,
    rules: [
      value =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!"
    ],
    favRules: []
  }),
  mounted() {
  },
  methods: {
    changeLogo(e) {
      this.setting.site_logo = e;
      this.logoUrl = URL.createObjectURL(e);
    },

    changeFavIcon(e) {
      this.setting.site_favicon = e;
      this.favIconUrl = URL.createObjectURL(e);
    }, changeAuthCoverImage(e) {
      this.setting.auth_cover_image = e;
      this.authCoverImageUrl = URL.createObjectURL(e);
    },
    removeImage(type) {
      this.$emit("remove_image", type);
    },
    uploadMedia() {
      let fd = new FormData();
      if (this.logoUrl !== null) {
        fd.append("site_logo", this.setting.site_logo, this.setting.site_logo);
      }
      if (this.favIconUrl !== null) {
        fd.append(
            "site_favicon",
            this.setting.site_favicon,
            this.setting.site_favicon.name
        );
      }
      if (this.authCoverImageUrl !== null) {
        fd.append(
            "auth_cover_image",
            this.setting.auth_cover_image,
            this.setting.auth_cover_image.name
        );
      }
      fd.append("logo_width", this.setting.logo_width);
      this.logoUrl = null
      this.favIconUrl = null
      this.$emit("upload_media", fd);
    }
  }
};
</script>
