<template>
  <v-row class="pa-5">
    <v-col cols="12">
      <h3>Action Button</h3>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <v-switch v-model="header.display_button" label="Enable/Disable"></v-switch>
    </v-col>

    <v-col cols="12" sm="12" md="6" v-if="header.display_button">
      <v-switch v-model="header.button_link_new_tab" label="Open in new tab"></v-switch>
    </v-col>

    <v-row v-if="header.display_button" class="pa-5">
      <v-col cols="12" sm="12" md="6">
        <v-text-field
            v-model="header.button_text"
            outlined
            densed
            label="Button text"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="header.display_button">
        <v-text-field
            v-model="header.button_link"
            outlined
            densed
            label="Button URL"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <h5>Button Colors</h5>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <label>Background Color</label>
        <verte
            menuPosition="bottom"
            model="hex"
            v-model="header.action_button_bg_color"
        ></verte>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <label>Text Color</label>
        <verte
            menuPosition="bottom"
            model="hex"
            v-model="header.action_button_text_color"
        >
          <svg viewBox="0 0 24 24">
            <path d="M0 20h24v4H0z"/>
            <path
                style="fill: #000"
                d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
            />
          </svg>
        </verte>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <label>Background Color Hover</label>
        <verte
            menuPosition="bottom"
            model="hex"
            v-model="header.action_button_bg_color_hover"
        ></verte>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <label>Text Color Hover</label>
        <verte
            menuPosition="bottom"
            model="hex"
            v-model="header.action_button_text_color_hover"
        >
          <svg viewBox="0 0 24 24">
            <path d="M0 20h24v4H0z"/>
            <path
                style="fill: #000"
                d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
            />
          </svg>
        </verte>
      </v-col>
    </v-row>


    <v-col class="mt-3"
        cols="12"
        sm="12"
        md="6"
        v-if="
                      website.subscription ? website.subscription.status : 0
                    "
    >
      <v-switch v-model="footer.enable_powered_by" label=" Powered by Yelko"></v-switch>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn bottom @click="saveHeader" :loading="isBusy" color="primary">save</v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'action-button-setting',
  props: ["website", "setting", "header", "footer", "isBusy"],
  methods: {
    saveHeader() {
      this.$emit('save_header');
    }
  }
}
</script>
