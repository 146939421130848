<template>
    <v-row class="pa-10">
        <h3>Header 6</h3>
        <v-col cols="12">
            <h5>Font</h5>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <label> Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.font_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <label>Hover Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.font_hover_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <v-col cols="12"></v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label=" Size"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.font_size"
            ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-select
                    :items="['100', '200', '300', '400', '500', '600', '700']"
                    dense
                    label=" Weight"
                    min="0"
                    outlined
                    type="number"
                    v-model="header_style.font_weight"
            ></v-select>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-autocomplete
                    :items="items"
                    :loading="isBusy"
                    :search-input.sync="search"
                    cache-items
                    class="mx-4"
                    dense
                    flat
                    hide-details
                    hide-no-data
                    item-text="Description"
                    item-value="Description"
                    label=" Family"
                    outlined
                    v-model="header_style.font_family"
            ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-select
                    :items="['underline', 'strike', 'overline']"
                    dense
                    label=" Decoration"
                    outlined
                    v-model="header_style.font_decoration"
            ></v-select>
        </v-col>

        <v-col cols="12" md="4" sm="12">
            <v-select
                    :items="['normal', 'italic', 'oblique']"
                    dense
                    label=" Style"
                    outlined
                    v-model="header_style.font_style"
            ></v-select>
        </v-col>

        <v-col cols="12" md="4" sm="12">
            <v-text-field
                    dense
                    label="Spacing"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.line_spacing"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="12">
            <v-text-field
                    dense
                    label="Line Height"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.line_height"
            ></v-text-field>
        </v-col>

        <v-col cols="12">
            <h6>Alignment</h6>
        </v-col>

        <v-col cols="12" md="6" sm="12">
            <v-select
                    :items="['left', 'center', 'right']"
                    dense
                    label="Horizontal"
                    outlined
                    v-model="header_style.font_text_position"
            ></v-select>
        </v-col>

        <v-col cols="12" md="6" sm="12">
            <v-select
                    :items="['top', 'middle', 'bottom']"
                    dense
                    label="Vertical"
                    outlined
                    v-model="header_style.font_text_vertical_position"
            ></v-select>
        </v-col>

        <v-col class="text-right" cols="12">
            <v-btn :loading="isBusy" @click="__delete" class="mr-4" color="red">Reset</v-btn>
            <v-btn :loading="isBusy" @click="saveOrUpdate" color="primary"
            >Save
            </v-btn
            >
        </v-col>
    </v-row>
</template>
<script>
    import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";
    import WebsiteStyleService from "@/services/Websites/WebsiteStyleService";

    const headerService = new WebsiteHeader();
    const styleService = new WebsiteStyleService();
    // header_style_style_id
    export default {
        name: "header-six",
        props: ["domain_name", "type", "header"],
        data: () => ({
            isBusy: false,
            search: null,
            header_style: {
                type: "h6",
                font_color: null,
                font_hover_color: null,
                font_size: null,
                font_family: null,
                font_weight: null,
                font_style: null,
                font_decoration: null,
                font_text_position: null,
                font_text_vertical_position: null,
                line_spacing: null,
                line_height: null,
                padding_all: null,
                padding_top: null,
                padding_bottom: null,
                padding_left: null,
                padding_right: null,
                margin_all: null,
                margin_top: null,
                margin_bottom: null,
                margin_left: null,
                margin_right: null,
                border_all: null,
                border_top: null,
                border_bottom: null,
                border_left: null,
                border_right: null,
                background_color: null,
                background_image: null,
                background_hr_position: null,
                background_hover_color: null,
                section_height: null,
                logo_height: null,
                ecommerce_icon_size: null,
                social_icon_size: null,
            },
            entries: [],
        }),
        mounted() {
            this.getStyle();
        },
        computed: {
            items() {
                return this.entries.map((entry, index) => {
                    const Description = entry.family;
                    return Object.assign({}, entry, {
                        Description,
                    });
                });
            },
        },
        watch: {
            search(val) {
                if (this.items.length > 0) return;
                if (this.isBusy) return;
                this.isBusy = true;
                headerService
                    .getGoogleFonts(val)
                    .then((res) => {
                        this.entries = res.data.items;
                    })
                    .catch((err) => {
                        // console.log(err);
                    })
                    .finally(() => (this.isBusy = false));
            },
        },
        methods: {
            getStyle() {
                styleService
                    .index(this.domain_name, "h6")
                    .then((response) => {
                        if (response.data.data) this.header_style = response.data.data;
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            saveOrUpdate() {
                if (this.header_style && this.header_style.id) {
                    this.update();
                } else {
                    this.save();
                }
            },
            save() {
                this.isBusy = true;
                styleService
                    .create(this.domain_name, this.header_style)
                    .then((response) => {
                        let data = {
                            header_style_id: response.data.style.id,
                            id: this.header_style.id,
                        };
                        this.$emit("update_header", data);
                        this.getStyle();
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            update() {
                this.isBusy = true;
                styleService
                    .update(this.domain_name, this.header_style.id, this.header_style)
                    .then((response) => {
                        let data = {
                            header_style_id: response.data.style.id,
                            id: this.header_style.id,
                        };
                        this.$emit("update_header", data);
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            __delete() {
                this.isBusy = true;
                styleService
                    .delete(this.domain_name, this.menu_style.id)
                    .then(response => {
                        this.$emit("update_header", data);
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.getStyle();
                    });
            }
        },
    };
</script>
