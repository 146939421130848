<template>
    <v-app>
      <v-row class="pa-5">
        <v-col cols="12">
          <h3>Top Bar</h3>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="header.show_top_bar" label="Enable Top Bar"></v-switch>
        </v-col>

        <v-col cols="12" v-if="header.show_top_bar">
          <h5>Content</h5>
        </v-col>
        <v-col cols="12" sm="12" md="3" v-if="header.show_top_bar">
          <v-switch v-model="header.display_phone" label="Phone"></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="3" v-if="header.show_top_bar">
          <v-switch v-model="header.display_email" label="Email"></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="3" v-if="header.show_top_bar">
          <v-switch v-model="header.display_social_links" label="Social Links"></v-switch>
        </v-col>

<!--        <v-row cols="12" v-if="header.transparent_header_style == 'custom'">-->
<!--          <v-col cols="12">-->
<!--            <h5>Header Colors</h5>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="12" md="3">-->
<!--            <label>Header Background Color</label>-->
<!--            <verte menuPosition="bottom" model="hex" v-model="header.header_background_color"></verte>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="12" md="3">-->
<!--            <label>Header Menu Text Color</label>-->
<!--            <verte menuPosition="bottom" model="hex" v-model="header.header_menu_text_color">-->
<!--              <svg viewBox="0 0 24 24">-->
<!--                <path d="M0 20h24v4H0z" />-->
<!--                <path-->
<!--                  style="fill: #000"-->
<!--                  d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"-->
<!--                />-->
<!--              </svg>-->
<!--            </verte>-->
<!--          </v-col>-->

<!--          <v-col cols="12" sm="12" md="3">-->
<!--            <label>Menu Hover Text Color</label>-->
<!--            <verte menuPosition="bottom" model="hex" v-model="header.menu_hover_text_color">-->
<!--              <svg viewBox="0 0 24 24">-->
<!--                <path d="M0 20h24v4H0z" />-->
<!--                <path-->
<!--                  style="fill: #000"-->
<!--                  d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"-->
<!--                />-->
<!--              </svg>-->
<!--            </verte>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <hr />
      </v-row>
      <v-col cols="12" class="text-right">
        <v-btn bottom @click="saveTopBar" :loading="isBusy" color="primary">save</v-btn>
      </v-col>
    </v-app>
</template>
<script>
import Websitelayout from "../LayoutDialog";

export default {
  name: "top-bar-setting",
  props: ["website", "setting", "header"],
  components: {
    Websitelayout
  },
  data: () => ({
    headerLayoutDialog: false,
    top_bar: {
      top_bar_font_size: null,
      top_bar_font_weight: null,
      top_bar_font_family: null,
      top_bar_font_style: null,
      top_bar_font_is_italic: null,
      top_bar_icon_size: null,
      top_bar_menu_item_padding: null,
      top_bar_menu_item_line_spacing: null,
      top_bar_menu_margin: null
    }
  }),
  methods: {
    saveTopBar() {
     this.$emit('save_header');

    }
  }
};
</script>
