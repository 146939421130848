<template>
      <v-row class="pa-5">
        <v-col cols="12">
          <h3>Footer</h3>
        </v-col>
        <v-col>
          <v-btn @click="footerLayoutDialog = true" class="btn btn-primary" outlined text>change Footer Layout</v-btn>
        </v-col>
        <v-dialog scrollable v-model="footerLayoutDialog" max-width="900px">
          <websitelayout :type="'footer'" @closeDialog="footerLayoutDialog = false"/>
        </v-dialog>
        <v-col cols="12">
          <h5>Logo</h5>
        </v-col>
        <v-col cols="12">
          <v-file-input
              v-model="footer.footer_logo"
              accept="image/*"
              label="Footer Logo"
              prepend-icon=""
              prepend-inner-icon="mdi-camera"
              outlined
              densed
          ></v-file-input>
        </v-col>
        <v-col cols="12">
          <div class="logoPreview row">
            <img style="max-height: 200px" v-if="footer.footer_logo_image" :src="footer.footer_logo_image['thumb']"/>
            <span class="ml-5" v-if="footer.footer_logo_image && footer.footer_logo_image['thumb']" @click="deleteFooterLogo"><i class="fas fa-trash"></i></span>
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="footer.logo_height"
              label="Logo Height"
              suffix="px"
              outlined
              densed
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="footer.copyright_text"
              label="Copyright Text"
              outlined
              densed
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
              v-model="footer.description"
              label="Description for about company widget on footer"
              outlined
              densed
          ></v-textarea>
        </v-col>


        <v-col cols="12">
          <h5>Content</h5>
        </v-col>

        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="footer.display_scroll_to_top" label=" Scroll to Top"></v-switch>
        </v-col>

        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="footer.display_footer_logo" label="Footer Logo"></v-switch>
        </v-col>

        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="footer.display_phone" label="Phone"></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="footer.display_email" label="Email"></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="footer.display_social_links" label="Social Links"></v-switch>
        </v-col>
        <!--                  <v-col cols="12" sm="12" md="3">-->
        <!--                    <v-switch-->
        <!--                      v-model="style.display_subscription"-->
        <!--                      label=" Newsletter"-->
        <!--                    ></v-switch>-->
        <!--                  </v-col>-->
        <!--                  <v-col cols="12" sm="12" md="3">-->
        <!--                    <v-switch-->
        <!--                      v-model="style.display_opening_hours"-->
        <!--                      label=" Opening Hours"-->
        <!--                    ></v-switch>-->
        <!--                  </v-col>-->
        <v-col cols="12" sm="12" md="3">
          <v-switch v-model="footer.display_business_address" label="Address"></v-switch>
        </v-col>
        <v-col cols="12" class="text-right">
          <v-btn bottom @click="saveFooter" :loading="isBusy" color="primary">save</v-btn>
        </v-col>
        <v-col
            cols="12"
            sm="12"
            md="6"
            v-if="
                      website.subscription ? website.subscription.status : 0
                    "
        >
          <v-switch v-model="footer.enable_powered_by" label=" Powered by Yelko"></v-switch>
        </v-col>
      </v-row>
</template>
<script>
import Websitelayout from "../LayoutDialog";

export default {
  name: "footer-setting",
  props: ["website", "setting", "footer", "isBusy"],
  components: {
    Websitelayout
  },
  data: () => ({
    footerLayoutDialog: false,
    footer_logo: null
  }),
  methods: {
    saveFooter() {
      this.$emit("save_footer");
    }, deleteFooterLogo(){
      this.$emit("delete_footer_logo");
    }
  }
};
</script>
