<template>
  <v-row class="pa-5">
    <v-col cols="12">
      <h5>Font</h5>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <label> Color</label>
      <verte menuPosition="bottom" model="hex" v-model="menu_style.font_color">
        <svg viewBox="0 0 24 24">
          <path d="M0 20h24v4H0z" />
          <path
              style="fill: #000"
              d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
          />
        </svg>
      </verte>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <label> Hover Color</label>
      <verte menuPosition="bottom" model="hex" v-model="menu_style.font_hover_color">
        <svg viewBox="0 0 24 24">
          <path d="M0 20h24v4H0z" />
          <path
              style="fill: #000"
              d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
          />
        </svg>
      </verte>
    </v-col>
    <v-col cols="12"></v-col>
    <v-col cols="12" sm="12" md="3">
      <v-text-field type="number" min="0" v-model="menu_style.font_size" label=" Size" suffix="px" outlined dense></v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <v-select
          :items="['100', '200', '300', '400', '500', '600', '700']"
          v-model="menu_style.font_weight"
          outlined
          dense type="number" min="0"
          label=" Weight"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <v-autocomplete
          v-model="menu_style.font_family"
          :loading="isBusy"
          :items="items"
          :search-input.sync="search"
          item-text="Description"
          item-value="Description"
          cache-items
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label=" Family"
          outlined
          dense
      ></v-autocomplete>
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <v-select
          :items="['underline', 'strike', 'overline']"
          v-model="menu_style.font_decoration"
          outlined
          dense
          label=" Decoration"
      ></v-select>
    </v-col>

<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-select-->
<!--          :items="['normal', 'italic', 'oblique']"-->
<!--          v-model="menu_style.font_style"-->
<!--          outlined-->
<!--          dense-->
<!--          label=" Style"-->
<!--      ></v-select>-->
<!--    </v-col>-->

<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-select-->
<!--          :items="['left',  'center', 'right']"-->
<!--          v-model="menu_style.font_text_position"-->
<!--          outlined-->
<!--          dense-->
<!--          label="Alignment"-->
<!--      ></v-select>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.line_spacing" label="Spacing" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.line_height" label="Line Height" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->

    <v-col cols="12">
      <h5>Padding</h5>
    </v-col>
    <v-col cols="12" sm="12" md="12">
      <v-text-field type="number" min="0" v-model="menu_style.padding_all" label="All" suffix="px" outlined dense></v-text-field>
    </v-col>

<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.padding_top" label="Top" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field-->
<!--          type="number" min="0" v-model="menu_style.padding_bottom"-->
<!--          label="Bottom"-->
<!--          suffix="px"-->
<!--          outlined-->
<!--          dense-->
<!--      ></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.padding_left" label="Left" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field-->
<!--          type="number" min="0" v-model="menu_style.padding_right"-->
<!--          label="Right"-->
<!--          suffix="px"-->
<!--          outlined-->
<!--          dense-->
<!--      ></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12">-->
<!--      <h5>Margin</h5>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="12">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.margin_all" label="All" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->

<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.margin_top" label="Top" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field-->
<!--          type="number" min="0" v-model="menu_style.margin_bottom"-->
<!--          label="Bottom"-->
<!--          suffix="px"-->
<!--          outlined-->
<!--          dense-->
<!--      ></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.margin_left" label="Left" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col cols="12" sm="12" md="3">-->
<!--      <v-text-field type="number" min="0" v-model="menu_style.margin_right" label="Right" suffix="px" outlined dense></v-text-field>-->
<!--    </v-col>-->
    <v-col cols="12">
      <h5>Border</h5>
    </v-col>

      <v-col cols="12" sm="12" md="12">
      <label>Color</label>
      <verte menuPosition="bottom" model="hex" v-model="menu_style.border_color">
        <svg viewBox="0 0 24 24">
          <path d="M0 20h24v4H0z" />
          <path
            style="fill: #000"
            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
          />
        </svg>
      </verte>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <v-select
        :items="['solid', 'dashed', 'dotted']"
        v-model="menu_style.border_type"
        outlined
        dense
        label="Border Type"
      ></v-select>
    </v-col>

    <v-col cols="12" sm="12" md="6">
      <v-text-field
        type="number"
        min="0"
        v-model="menu_style.border_all"
        label="All"
        suffix="px"
        outlined
        dense
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="12" md="3">
      <v-text-field type="number" min="0" v-model="menu_style.border_top" label="Top" suffix="px" outlined dense></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <v-text-field
          type="number" min="0" v-model="menu_style.border_bottom"
          label="Bottom"
          suffix="px"
          outlined
          dense
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <v-text-field type="number" min="0" v-model="menu_style.border_left" label="Left" suffix="px" outlined dense></v-text-field>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <v-text-field type="number" min="0" v-model="menu_style.border_right" label="Right" suffix="px" outlined dense></v-text-field>
    </v-col>
    <v-col cols="12">
      <h5>Background</h5>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <label>Color</label>
      <verte menuPosition="bottom" model="hex" v-model="menu_style.background_color">
        <svg viewBox="0 0 24 24">
          <path d="M0 20h24v4H0z" />
          <path
              style="fill: #000"
              d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
          />
        </svg>
      </verte>
    </v-col>
    <v-col cols="12" sm="12" md="3">
      <label>Hover Color</label>
      <verte menuPosition="bottom" model="hex" v-model="menu_style.background_hover_color">
        <svg viewBox="0 0 24 24">
          <path d="M0 20h24v4H0z" />
          <path
              style="fill: #000"
              d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
          />
        </svg>
      </verte>
    </v-col>

    <v-col cols="12" class="text-right">
      <v-btn @click="__delete" class="mr-4" :loading="isBusy" color="red">Reset</v-btn>
      <v-btn @click="saveOrUpdate" :loading="isBusy" color="primary">Save</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";
import WebsiteStyleService from "@/services/Websites/WebsiteStyleService";

const headerService = new WebsiteHeader();
const styleService = new WebsiteStyleService();
// menu_style_style_id
export default {
  name: "menu-style",
  props: ["domain_name", "type", "header"],
  data: () => ({
    isBusy: false,
    search: null,
    menu_style: {
      type: "menu_style",
      font_color: null,
      font_hover_color: null,
      font_size: null,
      font_family: null,
      font_weight: null,
      font_style: null,
      font_decoration: null,
      font_text_position: null,
      line_spacing: null,
      line_height: null,
      padding_all: null,
      padding_top: null,
      padding_bottom: null,
      padding_left: null,
      padding_right: null,
      margin_all: null,
      margin_top: null,
      margin_bottom: null,
      margin_left: null,
      margin_right: null,
      border_all: null,
      border_top: null,
      border_bottom: null,
      border_left: null,
      border_right: null,
      background_color: null,
      background_image: null,
      background_hr_position: null,
      background_hover_color: null,
      section_height: null,
      logo_height: null,
      ecommerce_icon_size: null,
      social_icon_size: null
    },
    entries: []
  }),
  mounted() {
    this.getStyle();
  },
  computed: {
    items() {
      return this.entries.map((entry, index) => {
        const Description = entry.family;
        return Object.assign({}, entry, {
          Description
        });
      });
    }
  },
  watch: {
    search(val) {
      if (this.items.length > 0) return;
      if (this.isBusy) return;
      this.isBusy = true;
      headerService
        .getGoogleFonts(val)
        .then(res => {
          this.entries = res.data.items;
        })
        .catch(err => {
          // console.log(err);
        })
        .finally(() => (this.isBusy = false));
    }
  },
  methods: {
    getStyle() {
      styleService
        .index(this.domain_name, "menu_style")
        .then(response => {
          if (response.data.data) this.menu_style = response.data.data;
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    saveOrUpdate() {
      if (this.menu_style && this.menu_style.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.isBusy = true;
      styleService
        .create(this.domain_name, this.menu_style)
        .then(response => {
          let data = {
                menu_style_style_id  : response.data.style.id,
                id : this.header.id
          }
          this.$emit("update_header", data);
          this.getStyle();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    update() {
      this.isBusy = true;
      styleService
        .update(this.domain_name, this.menu_style.id, this.menu_style)
        .then(response => {
          let data = {
               menu_style_style_id  : response.data.style.id,
                id : this.header.id
          }
          this.$emit("update_header", data);
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    __delete() {
      this.isBusy = true;
      styleService
              .delete(this.domain_name, this.menu_style.id)
              .then(response => {
                this.$emit("update_header", data);
              })
              .catch(error => {
                // console.log(error);
              })
              .finally(() => {
                this.isBusy = false;
                this.getStyle();
              });
    }
  }
};
</script>
