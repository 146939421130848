<template>
    <v-row class="pa-10">
        <v-col cols="12">
            <h5>Font</h5>
        </v-col>
        <v-col cols="12" md="4" sm="12">
            <label> Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.font_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <v-col cols="12" md="4" sm="12">
            <label>Hover Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.font_hover_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <v-col cols="12"></v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label=" Size"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.font_size"
            ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-select
                    :items="['100', '200', '300', '400', '500', '600', '700']"
                    dense
                    label=" Weight"
                    min="0"
                    outlined
                    type="number"
                    v-model="header_style.font_weight"
            ></v-select>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-autocomplete
                    :items="items"
                    :loading="isBusy"
                    :search-input.sync="search"
                    cache-items
                    class="mx-4"
                    dense
                    flat
                    hide-details
                    hide-no-data
                    item-text="Description"
                    item-value="Description"
                    label=" Family"
                    outlined
                    v-model="header_style.font_family"
            ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-select
                    :items="['underline', 'strike', 'overline']"
                    dense
                    label=" Decoration"
                    outlined
                    v-model="header_style.font_decoration"
            ></v-select>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-select
                    :items="['normal', 'italic', 'oblique']"
                    dense
                    label=" Style"
                    outlined
                    v-model="header_style.font_style"
            ></v-select>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-select
                    :items="['left', 'center', 'right']"
                    dense
                    label="Alignment"
                    outlined
                    v-model="header_style.font_text_position"
            ></v-select>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Spacing"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.line_spacing"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Line Height"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.line_height"
            ></v-text-field>
        </v-col>

        <v-col cols="12">
            <h5>Padding</h5>
        </v-col>
        <v-col cols="12" md="12" sm="12">
            <v-text-field
                    dense
                    label="All"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.padding_all"
            ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Top"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.padding_top"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Bottom"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.padding_bottom"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Left"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.padding_left"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Right"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.padding_right"
            ></v-text-field>
        </v-col>
        <!--    <v-col cols="12">-->
        <!--      <h5>Margin</h5>-->
        <!--    </v-col>-->
        <!--    <v-col cols="12" sm="12" md="12">-->
        <!--      <v-text-field-->
        <!--        type="number"-->
        <!--        min="0"-->
        <!--        v-model="header_style.margin_all"-->
        <!--        label="All"-->
        <!--        suffix="px"-->
        <!--        outlined-->
        <!--        dense-->
        <!--      ></v-text-field>-->
        <!--    </v-col>-->
        <!--    <v-col cols="12" sm="12" md="3">-->
        <!--      <v-text-field-->
        <!--        type="number"-->
        <!--        min="0"-->
        <!--        v-model="header_style.margin_top"-->
        <!--        label="Top"-->
        <!--        suffix="px"-->
        <!--        outlined-->
        <!--        dense-->
        <!--      ></v-text-field>-->
        <!--    </v-col>-->
        <!--    <v-col cols="12" sm="12" md="3">-->
        <!--      <v-text-field-->
        <!--        type="number"-->
        <!--        min="0"-->
        <!--        v-model="header_style.margin_bottom"-->
        <!--        label="Bottom"-->
        <!--        suffix="px"-->
        <!--        outlined-->
        <!--        dense-->
        <!--      ></v-text-field>-->
        <!--    </v-col>-->
        <!--    <v-col cols="12" sm="12" md="3">-->
        <!--      <v-text-field-->
        <!--        type="number"-->
        <!--        min="0"-->
        <!--        v-model="header_style.margin_left"-->
        <!--        label="Left"-->
        <!--        suffix="px"-->
        <!--        outlined-->
        <!--        dense-->
        <!--      ></v-text-field>-->
        <!--    </v-col>-->
        <!--    <v-col cols="12" sm="12" md="3">-->
        <!--      <v-text-field-->
        <!--        type="number"-->
        <!--        min="0"-->
        <!--        v-model="header_style.margin_right"-->
        <!--        label="Right"-->
        <!--        suffix="px"-->
        <!--        outlined-->
        <!--        dense-->
        <!--      ></v-text-field>-->
        <!--    </v-col>-->
        <v-col cols="12">
            <h5>Border</h5>
        </v-col>
        <v-col cols="12" md="12" sm="12">
            <v-text-field
                    dense
                    label="All"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.border_all"
            ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Top"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.border_top"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Bottom"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.border_bottom"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Left"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.border_left"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Right"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.border_right"
            ></v-text-field>
        </v-col>
        <v-col cols="12">
            <h5>Background</h5>
        </v-col>

        <v-col cols="12">
            <v-file-input
                    :rules="rules"
                    @change="changeBackgroundImage"
                    accept="image/png, image/jpeg, image/bmp"
                    hint="Upload Background Image"
                    label="Background Image"
                    outlined
                    persistent-hint
                    prepend-icon=""
                    prepend-inner-icon="mdi-camera"
                    v-model="header_style.background_image"
            ></v-file-input>
        </v-col>
        <v-col cols="12">
            <div class="imagePreview" id="imagePreview">
                <img
                        :src="header_style.background_image_path"
                        style="max-height: 180px"
                        v-if="header_style.background_image_path"
                />
                <a
                        @click.prevent="removeBackgroundImage()"
                        class="ml-5 btn btn-sm"
                        href="#"
                        v-if="header_style.background_image_path"
                >
                    <i class="fas fa-trash"></i>
                </a>
            </div>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <label>Background Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.background_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <!--    <v-col cols="12" sm="12" md="3">-->
        <!--      <label>Hover Color</label>-->
        <!--      <verte menuPosition="bottom" model="hex" v-model="header_style.background_hover_color">-->
        <!--        <svg viewBox="0 0 24 24">-->
        <!--          <path d="M0 20h24v4H0z" />-->
        <!--          <path-->
        <!--            style="fill: #000"-->
        <!--            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"-->
        <!--          />-->
        <!--        </svg>-->
        <!--      </verte>-->
        <!--    </v-col>-->

        <v-col cols="12"></v-col>
        <v-col cols="12" md="6" sm="12">
            <v-select
                    :items="['left', 'center', 'right']"
                    dense
                    label="Background hr Position"
                    outlined
                    v-model="header_style.background_hr_position"
            ></v-select>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-select
                    :items="['top', 'center', 'bottom']"
                    dense
                    label="Image Vertical Position"
                    outlined
                    v-model="header_style.background_vr_position"
            ></v-select>
        </v-col>

        <v-col cols="12">
            <h5>Icons</h5>
        </v-col>

        <v-col cols="12" md="12" sm="12">
            <label>Ecommerce Icon Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.ecommerce_icon_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <v-col cols="12" md="12" sm="12">
            <label>Social Icon Color</label>
            <verte
                    menuPosition="bottom"
                    model="hex"
                    v-model="header_style.social_icon_color"
            >
                <svg viewBox="0 0 24 24">
                    <path d="M0 20h24v4H0z"/>
                    <path
                            d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                            style="fill: #000"
                    />
                </svg>
            </verte>
        </v-col>
        <v-col cols="12" md="6" sm="12">
            <v-select
                    :items="['small', 'medium', 'large', 'xlarge']"
                    dense
                    label="Ecommerce Icon Size"
                    outlined
                    v-model="header_style.ecommerce_icon_size"
            ></v-select>
        </v-col>


        <v-col cols="12" md="6" sm="12">
            <v-select
                    :items="['small', 'medium', 'large', 'xlarge']"
                    dense
                    label="Social Icon Size"
                    outlined
                    v-model="header_style.social_icon_size"
            ></v-select>
        </v-col>

        <v-col cols="12">
            <h5>Others</h5>
        </v-col>

        <v-col cols="12" md="3" sm="12">
            <v-text-field
                    dense
                    label="Height"
                    min="0"
                    outlined
                    suffix="px"
                    type="number"
                    v-model="header_style.section_height"
            ></v-text-field>
        </v-col>
        <v-col class="text-right" cols="12">
            <v-btn :loading="isBusy" @click="__delete" class="mr-4" color="red">Reset</v-btn>
            <v-btn :loading="isBusy" @click="saveOrUpdate" color="primary"
            >Save
            </v-btn
            >
        </v-col>
    </v-row>
</template>
<script>
    import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";
    import WebsiteStyleService from "@/services/Websites/WebsiteStyleService";

    const headerService = new WebsiteHeader();
    const styleService = new WebsiteStyleService();
    // header_style_style_id
    export default {
        name: "top-bar-style",
        props: ["domain_name", "type", "header"],
        data: () => ({
            isBusy: false,
            search: null,
            backgroundImage: null,
            header_style: {
                type: "header",
                font_color: null,
                font_hover_color: null,
                font_size: null,
                font_family: null,
                font_weight: null,
                font_style: null,
                font_decoration: null,
                font_text_position: null,
                line_spacing: null,
                line_height: null,
                padding_all: null,
                padding_top: null,
                padding_bottom: null,
                padding_left: null,
                padding_right: null,
                margin_all: null,
                margin_top: null,
                margin_bottom: null,
                margin_left: null,
                margin_right: null,
                border_all: null,
                border_top: null,
                border_bottom: null,
                border_left: null,
                border_right: null,
                background_color: null,
                background_image: null,
                background_hr_position: null,
                background_vr_position: null,
                background_hover_color: null,
                section_height: null,
                logo_height: null,
                ecommerce_icon_size: null,
                ecommerce_icon_color: null,
                social_icon_size: null,
                social_icon_size: null,
            },
            entries: [],
            rules: [
                (value) =>
                    !value ||
                    value.size < 2000000 ||
                    "Image size should be less than 2 MB!",
            ],
        }),
        mounted() {
            this.getStyle();
        },
        computed: {
            items() {
                return this.entries.map((entry, index) => {
                    const Description = entry.family;
                    return Object.assign({}, entry, {
                        Description,
                    });
                });
            },

            siteUrl() {
                return this.$route.params.domainname;
            },
        },
        watch: {
            search(val) {
                if (this.items.length > 0) return;
                if (this.isBusy) return;
                this.isBusy = true;
                headerService
                    .getGoogleFonts(val)
                    .then((res) => {
                        this.entries = res.data.items;
                    })
                    .catch((err) => {
                        // console.log(err);
                    })
                    .finally(() => (this.isBusy = false));
            },
        },
        methods: {
            getStyle() {
                styleService
                    .index(this.domain_name, "header")
                    .then((response) => {
                        if (response.data.data) this.header_style = response.data.data;
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },

            changeBackgroundImage(e) {
                this.header_style.background_image = e;

                this.backgroundImage = URL.createObjectURL(e);
            },
            removeBackgroundImage() {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: (confirm) => {
                        if (confirm) {
                            styleService
                                .removeBackgroundImage(this.siteUrl, this.header_style.id)
                                .then((response) => {
                                    this.$snotify.success("Background image deleted");
                                    this.getStyle();
                                })
                                .catch((error) => {
                                    // console.log(error);
                                });
                        }
                    },
                });
            },
            saveOrUpdate() {
                let fd = new FormData();
                for (var key in this.header_style) {
                    if (
                        key === "background_image" &&
                        (this.header_style["background_image"] == null ||
                            this.header_style["background_image"] == undefined)
                    ) {
                    } else {
                        fd.append(key, this.header_style[key]);
                    }
                }
                if (this.header_style && this.header_style.id) {
                    this.update(fd);
                } else {
                    this.save(fd);
                }
            },
            save(fd) {
                this.isBusy = true;
                styleService
                    .create(this.domain_name, fd)
                    .then((response) => {
                        this.$snotify.success("Setting saved");
                        this.getStyle();
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            update(fd) {
                this.isBusy = true;
                styleService
                    .update(this.domain_name, this.header_style.id, fd)
                    .then((response) => {
                        this.$snotify.success("Setting saved");
                        this.getStyle();
                    })
                    .catch((error) => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            },
            __reset() {
                this.header_style = {
                    type: "header",
                    font_color: null,
                    font_hover_color: null,
                    font_size: null,
                    font_family: null,
                    font_weight: null,
                    font_style: null,
                    font_decoration: null,
                    font_text_position: null,
                    line_spacing: null,
                    line_height: null,
                    padding_all: null,
                    padding_top: null,
                    padding_bottom: null,
                    padding_left: null,
                    padding_right: null,
                    margin_all: null,
                    margin_top: null,
                    margin_bottom: null,
                    margin_left: null,
                    margin_right: null,
                    border_all: null,
                    border_top: null,
                    border_bottom: null,
                    border_left: null,
                    border_right: null,
                    background_color: null,
                    background_image: null,
                    background_hr_position: null,
                    background_vr_position: null,
                    background_hover_color: null,
                    section_height: null,
                    logo_height: null,
                    ecommerce_icon_size: null,
                    ecommerce_icon_color: null,
                    social_icon_size: null,
                    social_icon_size: null,
                }
            },
            __delete() {
                this.isBusy = true;
                styleService
                    .delete(this.domain_name, this.header_style.id)
                    .then(response => {
                        this.__reset();
                        this.$emit("update_header", data);
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                        this.getStyle();
                    });
            }
        },
    };
</script>
