<template>

            <v-row class="pa-5">
                <v-col cols="12">
                    <h3>Maintenance Mode</h3>
                </v-col>

                <v-col cols="12">
                    <v-switch
                            v-model="setting.enable_coming_soon"
                            label="Enable Coming Soon"
                    ></v-switch>
                </v-col>
                <v-col cols="12">
                      <v-text-field
                              v-model="setting.coming_soon_user_name"
                              label="User Name"
                              dense
                              required
                              outlined
                      ></v-text-field>
                  </v-col>
                <v-col cols="12">
                    <v-text-field
                            v-model="setting.coming_soon_password"
                            label="Password"
                            dense
                            required
                            outlined
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="text-right">
                    <v-btn
                            @click="updateSetting"
                            :loading="isBusy"
                            color="primary"
                    >Save
                    </v-btn
                    >
                </v-col>
            </v-row>
</template>
<script>
    import WebsitePage from "@/services/Websites/WebsitePageService";

    const websitePageService = new WebsitePage();
    export default {
        name: 'maintenance-setting',
        props: ['setting', "isBusy"],
        data: () => ({
            pages: [],
        }),
        methods: {
            getPages(type = "default", flag = null) {
                websitePageService.paginate(this.siteUrl, type, flag).then(res => {
                    this.pages = res.data.pages;
                });
            },
            updateSetting() {
                this.$emit('update_setting', this.setting);
            }
        }

    }
</script>
