<template>
    <v-app>
        <v-row class="pa-5">
            <v-tabs :vertical="!$vuetify.breakpoint.xsOnly" class="theme_settings">
                <v-tab>Body</v-tab>
                <v-tab>Typography</v-tab>
                <v-tab>Top Bar</v-tab>
                <v-tab>Header</v-tab>
                <v-tab>Menu Style</v-tab>
                <v-tab>Sticky Header</v-tab>
                <v-tab>Sub Header</v-tab>
                <v-tab>Action Button</v-tab>
                <v-tab>Footer Style</v-tab>
                <v-tab>Custom CSS</v-tab>
                <v-tab-item>
                    <body-style :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                v-if="domain_name"></body-style>
                </v-tab-item>
                <v-tab-item>
                    <v-row class="pa-5">
                        <v-col cols="12" md="12" sm="12">
                            <v-select
                                    :items="['h1','h2','h3','h4','h5','h6']"
                                    dense
                                    label="Setup Styling For"
                                    outlined
                                    v-model="select"
                            ></v-select>
                        </v-col>

                        <header-one :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                    v-if="domain_name && select ==='h1'"></header-one>
                        <header-two :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                    v-if="domain_name && select ==='h2'"></header-two>
                        <header-three :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                      v-if="domain_name && select ==='h3' "></header-three>
                        <header-four :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                     v-if="domain_name && select ==='h4'"></header-four>
                        <header-five :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                     v-if="domain_name && select ==='h5'"></header-five>
                        <header-six :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                    v-if="domain_name && select ==='h6'"></header-six>
                    </v-row>
                </v-tab-item>

                <v-tab-item>
                    <top-bar-style :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                   v-if="domain_name"></top-bar-style>
                </v-tab-item>
                <v-tab-item>
                    <header-style :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                  v-if="domain_name"></header-style>
                </v-tab-item>
                <v-tab-item>
                    <menu-style  :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                 v-if="domain_name"> </menu-style>

                </v-tab-item>
                <v-tab-item>
                    <sticky-header-style :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                         v-if="domain_name"></sticky-header-style>
                </v-tab-item>
                <v-tab-item>
                    <sub-header-style :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                      v-if="domain_name"></sub-header-style>
                </v-tab-item>
                <v-tab-item>
                    <action-button-style :domain_name="domain_name" :header="header" @update_header="saveOrUpdateHeader"
                                         v-if="domain_name"></action-button-style>
                </v-tab-item>
                <v-tab-item>
                    <footer-style :domain_name="domain_name" :footer="footer" @update_footer="saveOrUpdateFooter"
                                  v-if="domain_name"></footer-style>
                </v-tab-item>

                <v-tab-item>
                    <v-col cols="12">
                        <h5>Your custom Css Code here</h5>
                        <prism-editor
                                :line-numbers="lineNumbers"
                                autoStyleLineNumbers
                                class="my-editor"
                                language="css"
                                v-model="setting.custom_css"
                        />
                        <h5>Your custom Javascript Code here</h5>
                        <prism-editor :line-numbers="lineNumbers" class="my-editor" language="js"
                                      style="height: 300px" v-model="setting.custom_js"/>
                    </v-col>
                    <v-col class="text-right" cols="12">
                        <v-btn :loading="isBusy" @click="updateSetting" color="primary">Save</v-btn>
                    </v-col>
                </v-tab-item>
            </v-tabs>
        </v-row>
    </v-app>
</template>
<script>
    import WebsiteHeader from "@/services/Websites/WebsiteHeaderService";
    import WebsiteFooter from "@/services/Websites/WebsiteFooterService";

    import PrismEditor from "vue-prism-editor";
    import "prismjs";
    import "prismjs/themes/prism-tomorrow.css";
    //vue-prism-editor dependency
    import "vue-prism-editor/dist/VuePrismEditor.css";
    // components
    import BodyStyle from "./custom-style/BodyStyle";
    import HeaderOne from "./custom-style/HeaderOne";
    import HeaderTwo from "./custom-style/HeaderTwo";
    import HeaderThree from "./custom-style/HeaderThree";
    import HeaderFour from "./custom-style/HeaderFour";
    import HeaderFive from "./custom-style/HeaderFive";
    import HeaderSix from "./custom-style/HeaderSix";
    import TopBarStyle from "./custom-style/TopBarStyle";
    import HeaderStyle from "./custom-style/HeaderStyle";
    import StickyHeaderStyle from "./custom-style/StickyHeaderStyle";
    import SubHeaderStyle from "./custom-style/SubHeaderStyle";
    import FooterStyle from "./custom-style/FooterStyle";
    import ActionButtonStyle from "./custom-style/ActionButton";
    import CustomCss from "@/views/websites/InternalPage/setting/custom-style/CustomCss";
    import MenuStyle from "./custom-style/MenuStyle";

    const headerService = new WebsiteHeader();
    const footerService = new WebsiteFooter();

    export default {
        name: "custom-style-setting",
        props: ["domain_name", "setting", "header", "footer", "isBusy"],
        components: {
            MenuStyle,
            PrismEditor,
            TopBarStyle,
            HeaderStyle,
            StickyHeaderStyle,
            SubHeaderStyle,
            FooterStyle,
            ActionButtonStyle,
            CustomCss,
            BodyStyle,
            HeaderOne,
            HeaderTwo,
            HeaderThree,
            HeaderFour,
            HeaderFive,
            HeaderSix
        },
        data: () => ({
            lineNumbers: true,
            select: 'h1'
        }), mounted() {
        },
        methods: {
            updateSetting() {
                this.$emit("update_setting", this.setting);
            },

            saveOrUpdateHeader() {
                if (this.header && this.header.id) {

                    this.updateHeader(this.header)
                } else {
                    this.createHeader(this.header)
                }
            },

            updateHeader(data) {
                headerService.update(this.domain_name, this.header.id, data).then(
                    res => {
                        this.$snotify.success("Settings updated");
                        this.initSetting();
                    }
                );
            },

            createHeader(data) {
                headerService.create(this.domain_name, data).then(
                    res => {
                        this.$snotify.success("Settings updated");
                        this.initSetting();
                    }
                );
            },

            saveOrUpdateFooter(data) {
                if (data && data.id) {
                    this.updateFooter(data)
                } else {
                    this.createFooter(data)
                }
            },

            updateFooter(data) {
                footerService.update(this.domain_name, this.footer.id, data).then(
                    res => {
                        this.$snotify.success("Settings updated");
                        this.initSetting();
                    }
                );
            },

            createFooter(data) {
                footerService.create(this.domain_name, data).then(
                    res => {
                        this.$snotify.success("Settings updated");
                        this.initSetting();
                    }
                );
            },

            initSetting() {
                this.$emit('init_setting')
            }
        }
    };
</script>
