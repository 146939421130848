import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteTopbartopbarService {

    enableDisablePage(websiteSlug, pageTitle) {
        let url = API_URL + 'user/website/' + websiteSlug + '/page/' + pageTitle + '/enable-disable'
        return apiService.get(url);

    }
    paginate(slug, index = null) {
        if (slug != undefined) {
            let url = API_URL + 'user/website/' + slug + '/topbar';
            if (index != null)
                url = url + '?page=' + index
            return apiService.get(url);
        }
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/topbar'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/topbar/' + id + "/update"
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/topbar/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/topbar/' + id
        return apiService.get(url);
    }




}
